export const UserIcon = () => (
	<svg
		width='12'
		height='14'
		viewBox='0 0 12 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M1.33073 12.3335H10.6641V13.6668H1.33073V12.3335ZM5.9974 11.0002C4.58291 11.0002 3.22635 10.4383 2.22616 9.43807C1.22597 8.43787 0.664063 7.08132 0.664062 5.66683C0.664062 4.25234 1.22597 2.89579 2.22616 1.89559C3.22635 0.895399 4.58291 0.333496 5.9974 0.333496C7.41188 0.333496 8.76844 0.895399 9.76863 1.89559C10.7688 2.89579 11.3307 4.25234 11.3307 5.66683C11.3307 7.08132 10.7688 8.43787 9.76863 9.43807C8.76844 10.4383 7.41188 11.0002 5.9974 11.0002ZM5.9974 9.66683C7.05826 9.66683 8.07568 9.2454 8.82582 8.49526C9.57597 7.74511 9.9974 6.7277 9.9974 5.66683C9.9974 4.60596 9.57597 3.58855 8.82582 2.8384C8.07568 2.08826 7.05826 1.66683 5.9974 1.66683C4.93653 1.66683 3.91911 2.08826 3.16897 2.8384C2.41882 3.58855 1.9974 4.60596 1.9974 5.66683C1.9974 6.7277 2.41882 7.74511 3.16897 8.49526C3.91911 9.2454 4.93653 9.66683 5.9974 9.66683Z'
			fill='white'
		/>
	</svg>
);
