/**
 * Returns the style value corresponding to the provided option, or the default style if the option is not found.
 * @param {string | number} option - The option to match.
 * @param {object} options - The object containing styles, with keys representing options and values representing corresponding styles.
 * @param {string | number} options.default - The default style to return if the option is not found.
 * @returns {string | number} The style value corresponding to the provided option, or the default style if the option is not found.
 * @example
 * // Example usage:
 * const option = 'dark';
 * const styles = {
 *   default: 'light',
 *   dark: '#333',
 *   light: '#FFF',
 *   // Add more styles here as needed
 * };
 * const result = matchStyle(option, styles); // Returns '#333'
 */
export const matchStyle = (
	option?: string | number,
	options: {
		default: string | number;
		[key: string]: string | number | undefined;
	} = {
		default: '',
	}
) => options[option ?? ''] ?? options.default;
