import { Text } from '../Text';
import type { ITableRowCell } from './Table.helper';

export const TableRowCell = <E,>({ item, column, style, className }: ITableRowCell<E>) => {
	const itemValue = column.dataIndex ? item[column.dataIndex] : undefined;
	const renderedValue = column.render ? column.render({ row: item, column }) : (itemValue as React.ReactNode);

	return (
		<td
			className={`bt_table_data_item ${className}`}
			style={style}
		>
			<Text
				variant='body_small_regular'
				as='span'
			>
				{renderedValue}
			</Text>
		</td>
	);
};
