import type { IApiPaginatedFilterQuery, IApiResponse, IApiResponsePaginated, IBillTx } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const billsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBills: builder.query<IApiResponsePaginated<IBillTx[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/bills', params }),
		}),
		getSingleBill: builder.query<IApiResponse<IBillTx>, string | undefined>({
			query: (txReference) => ({
				url: `/admin/bills/bill/${txReference}`,
			}),
		}),
		getUserBills: builder.query<IApiResponsePaginated<IBillTx[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId, ...params }) => ({ url: `/admin/bills/users/${userId}`, params }),
		}),
	}),
});

export const { useGetBillsQuery, useGetSingleBillQuery, useGetUserBillsQuery } = billsApiSlice;
