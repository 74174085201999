import type { IApiResponse } from 'BreetHelpers';

import type { LoginFormSchemaType } from '@/features/auth/login/Login.helper';

import { apiSlice } from './apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		loginRegular: builder.mutation<IApiResponse, LoginFormSchemaType>({
			query: (body) => ({
				url: '/auth/login/admin',
				method: 'POST',
				body,
			}),
		}),
		loginWithOtp: builder.mutation<IApiResponse, { email: string; otp: string }>({
			query: (body) => ({
				url: '/auth/login/admin',
				method: 'POST',
				body,
			}),
		}),
		logoutUser: builder.mutation<IApiResponse, void>({
			query: (body) => ({
				url: '/auth/logout/user',
				method: 'DELETE',
				body,
			}),
		}),
	}),
});

export const { useLoginRegularMutation, useLoginWithOtpMutation, useLogoutUserMutation } = authApiSlice;
