import { mapArrayFromLength } from 'BreetHelpers';
import { useCallback, useState } from 'react';

import { LoaderIcon } from '@/assets/icons';

import { SkeletonLoader } from '../Loader';
import { Text } from '../Text';
import type { ITableBody } from './Table.helper';
import { getInitialFixedLeftPos, getLastIndexOfFixedColumn } from './Table.helper';
import { TableRowCell } from './TableRowCell';

export const TableBody = <E extends { _id?: string }>({
	data,
	columns,
	isLoading,
	pageLimit = 10,
	withCheckbox,
	checkedItems,
	emptyDataText = 'No data',
	onRowClick,
	onRowCheckToggle,
	useCheckBox,
}: ITableBody<E>) => {
	const [isRowChecked, setIsRowChecked] = useState(false);

	const onTableRowCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsRowChecked(e.target.checked);
	}, []);

	const onTableRowCheckboxClick = useCallback(
		(rowItem: E) => (e: React.MouseEvent<HTMLTableCellElement>) => {
			e.preventDefault();
			if (onRowCheckToggle) onRowCheckToggle(rowItem);
		},
		[onRowCheckToggle]
	);

	const onTableRowClickHandler = useCallback(
		(rowItem: E) => () => {
			if (onRowClick) onRowClick(rowItem);
		},
		[onRowClick]
	);

	if (!data?.length)
		return (
			<tr className='bt_table_body_row_item'>
				<td colSpan={columns.length}>
					<div className='empty_table_data__wrapper'>
						<div className='align-flex-center'>
							<LoaderIcon />
							<Text
								variant='body_default_bold'
								as='span'
							>
								{emptyDataText}
							</Text>
						</div>
					</div>
				</td>
			</tr>
		);

	const tableCellPlaceholderCount = mapArrayFromLength(pageLimit);

	return isLoading
		? tableCellPlaceholderCount.map((item) => (
				<tr
					key={`table-body-${item}`}
					className='bt_table_body_row_item'
				>
					{withCheckbox && (
						<td className='bt_table_data_item bt_table_checkbox loading'>
							<input
								className='bt_table_checkbox_input'
								id={`bt_table_checkbox-${item}`}
								type='checkbox'
							/>
							<label
								className='bt_table_checkbox_label'
								htmlFor={`bt_table_checkbox-${item}`}
							>
								<span className='bt_table_checkbox_label_content'>-</span>
							</label>
						</td>
					)}
					{columns.map((column, columnIndex) => (
						<td
							key={`table-row-cell-${column.key}-loading`}
							style={{
								width: column.width,
								left: column.fixed
									? `${columns.slice(0, columnIndex).reduce((acc, curr) => acc + (curr.width ?? 0), getInitialFixedLeftPos(useCheckBox))}px`
									: 'unset',
								zIndex: column.fixed ? 3 : 0,
							}}
							className={`bt_table_data_item ${columnIndex === getLastIndexOfFixedColumn(columns) ? ' last_index' : ''}`}
						>
							<SkeletonLoader height='3.3rem' />
						</td>
					))}
				</tr>
			))
		: data.map((item) => (
				<tr
					key={`table-body-${item._id}`}
					onClick={onTableRowClickHandler(item)}
					className='bt_table_body_row_item'
				>
					{withCheckbox && (
						<td
							onClick={onTableRowCheckboxClick(item)}
							className='bt_table_data_item bt_table_checkbox'
						>
							<input
								checked={isRowChecked}
								onChange={onTableRowCheckboxChange}
								className='bt_table_checkbox_input'
								id={`bt_table_checkbox-${item._id}`}
								type='checkbox'
							/>
							<label
								className='bt_table_checkbox_label'
								htmlFor={`bt_table_checkbox-${item._id}`}
							>
								<span
									className={`bt_table_checkbox_label_content ${item._id && checkedItems.includes(item) ? 'checked' : 'default'}`}
								>
									-
								</span>
							</label>
						</td>
					)}
					{columns.map((column, columnIndex) => (
						<TableRowCell
							key={`table-row-cell-${column.key}`}
							item={item}
							column={column}
							style={{
								width: column.width,
								left: column.fixed
									? `${columns.slice(0, columnIndex).reduce((acc, curr) => acc + (curr.width ?? 0), getInitialFixedLeftPos(useCheckBox))}px`
									: 'unset',
								zIndex: column.fixed ? 3 : 0,
							}}
							className={columnIndex === getLastIndexOfFixedColumn(columns) ? ' last_index' : ''}
						/>
					))}
				</tr>
			));
};
