import { useCallback, useState } from 'react';

export const useEditMode = () => {
	const [editMode, setEditMode] = useState(false);

	const toggleEditModeHandler = useCallback(() => {
		setEditMode((prevData) => !prevData);
	}, []);

	return {
		editMode,
		toggleEditModeHandler,
	};
};
