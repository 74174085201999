import './styles/main.scss';

import { Loader } from 'BreetComponents';
import { store } from 'BreetRedux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { BaseLayout } from './layout';
import { routes } from './routes';

const router = createBrowserRouter(routes, { basename: '/' });

ReactDOM.createRoot(document.getElementById('root') as Element).render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<RouterProvider
				router={router}
				fallbackElement={
					<BaseLayout>
						<Loader />
					</BaseLayout>
				}
			/>
		</ReduxProvider>
	</React.StrictMode>
);
