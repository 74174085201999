export const UserMgtIcon = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_7161_3849)'>
			<path
				d='M9.55 11.5C8.95326 11.5 8.38097 11.2629 7.95901 10.841C7.53705 10.419 7.3 9.84674 7.3 9.25C7.3 8.65326 7.53705 8.08097 7.95901 7.65901C8.38097 7.23705 8.95326 7 9.55 7C10.1467 7 10.719 7.23705 11.141 7.65901C11.5629 8.08097 11.8 8.65326 11.8 9.25C11.8 9.84674 11.5629 10.419 11.141 10.841C10.719 11.2629 10.1467 11.5 9.55 11.5ZM10 19.748V16.4C10 15.912 10.144 15.463 10.404 15.062C9.51266 14.9352 8.60468 14.9957 7.73806 15.2397C6.87144 15.4837 6.06525 15.9058 5.371 16.479C6.46407 18.0966 8.11014 19.2594 10 19.749V19.748ZM4.453 14.66C5.91349 13.5792 7.68308 12.9971 9.5 13C10.543 13 11.543 13.188 12.467 13.532C13.345 13.189 14.392 13 15.5 13C17.16 13 18.685 13.424 19.706 14.156C20.0435 12.9459 20.0913 11.6733 19.8455 10.4412C19.5997 9.20918 19.0671 8.05236 18.291 7.06444C17.5149 6.07652 16.517 5.28527 15.3782 4.75478C14.2394 4.2243 12.9916 3.96948 11.736 4.01097C10.4803 4.05246 9.2521 4.38909 8.15077 4.9936C7.04945 5.59811 6.10597 6.4535 5.39676 7.4905C4.68755 8.5275 4.23254 9.71695 4.06858 10.9625C3.90461 12.2081 4.03631 13.4748 4.453 14.66ZM18.879 16.086C18.486 15.553 17.171 15 15.5 15C13.494 15 12 15.797 12 16.4V20C13.3878 20.0008 14.752 19.6403 15.9583 18.954C17.1646 18.2678 18.1715 17.2794 18.88 16.086H18.879ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM15.5 12.5C14.9696 12.5 14.4609 12.2893 14.0858 11.9142C13.7107 11.5391 13.5 11.0304 13.5 10.5C13.5 9.96957 13.7107 9.46086 14.0858 9.08579C14.4609 8.71071 14.9696 8.5 15.5 8.5C16.0304 8.5 16.5391 8.71071 16.9142 9.08579C17.2893 9.46086 17.5 9.96957 17.5 10.5C17.5 11.0304 17.2893 11.5391 16.9142 11.9142C16.5391 12.2893 16.0304 12.5 15.5 12.5Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7161_3849'>
				<rect
					width='24'
					height='24'
					fill='currentColor'
				/>
			</clipPath>
		</defs>
	</svg>
);
