export const StarMoonIcon = ({ type }: { type: 'star' | 'moon' }) =>
	type === 'star' ? (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.9974 11.9993C6.93653 11.9993 5.91911 11.5779 5.16897 10.8278C4.41882 10.0776 3.9974 9.06021 3.9974 7.99935C3.9974 6.93848 4.41882 5.92107 5.16897 5.17092C5.91911 4.42078 6.93653 3.99935 7.9974 3.99935C9.05826 3.99935 10.0757 4.42078 10.8258 5.17092C11.576 5.92107 11.9974 6.93848 11.9974 7.99935C11.9974 9.06021 11.576 10.0776 10.8258 10.8278C10.0757 11.5779 9.05826 11.9993 7.9974 11.9993ZM7.9974 10.666C8.70464 10.666 9.38292 10.3851 9.88301 9.88497C10.3831 9.38487 10.6641 8.70659 10.6641 7.99935C10.6641 7.2921 10.3831 6.61383 9.88301 6.11373C9.38292 5.61363 8.70464 5.33268 7.9974 5.33268C7.29015 5.33268 6.61187 5.61363 6.11178 6.11373C5.61168 6.61383 5.33073 7.2921 5.33073 7.99935C5.33073 8.70659 5.61168 9.38487 6.11178 9.88497C6.61187 10.3851 7.29015 10.666 7.9974 10.666ZM7.33073 0.666016H8.66406V2.66602H7.33073V0.666016ZM7.33073 13.3327H8.66406V15.3327H7.33073V13.3327ZM2.34073 3.28535L3.2834 2.34268L4.6974 3.75668L3.75473 4.69935L2.34073 3.28602V3.28535ZM11.2974 12.242L12.2401 11.2993L13.6541 12.7133L12.7114 13.656L11.2974 12.242ZM12.7114 2.34202L13.6541 3.28535L12.2401 4.69935L11.2974 3.75668L12.7114 2.34268V2.34202ZM3.75473 11.2993L4.6974 12.242L3.2834 13.656L2.34073 12.7133L3.75473 11.2993ZM15.3307 7.33268V8.66602H13.3307V7.33268H15.3307ZM2.66406 7.33268V8.66602H0.664062V7.33268H2.66406Z'
				fill='currentColor'
			/>
		</svg>
	) : (
		<svg
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.66927 3.66732C5.66914 4.59441 5.94514 5.50054 6.46209 6.27013C6.97903 7.03972 7.71349 7.63789 8.57179 7.98836C9.43009 8.33883 10.3733 8.42572 11.2812 8.23793C12.1891 8.05014 13.0204 7.59619 13.6693 6.93398V7.00065C13.6693 10.6827 10.6846 13.6673 7.0026 13.6673C3.3206 13.6673 0.335938 10.6827 0.335938 7.00065C0.335938 3.31865 3.3206 0.333984 7.0026 0.333984H7.06927C6.6253 0.768068 6.2727 1.28663 6.03226 1.85911C5.79182 2.43158 5.6684 3.0464 5.66927 3.66732ZM1.66927 7.00065C1.66879 8.19064 2.06629 9.3466 2.79853 10.2846C3.53078 11.2226 4.55569 11.8888 5.71021 12.1772C6.86473 12.4656 8.08253 12.3595 9.16983 11.876C10.2571 11.3924 11.1515 10.5591 11.7106 9.50865C10.7156 9.74308 9.67713 9.71937 8.69382 9.4398C7.71051 9.16022 6.81494 8.63404 6.09208 7.91118C5.36922 7.18832 4.84304 6.29275 4.56346 5.30944C4.28388 4.32613 4.26018 3.28769 4.4946 2.29265C3.64099 2.74741 2.92713 3.42576 2.42944 4.25508C1.93176 5.0844 1.66899 6.03346 1.66927 7.00065Z'
				fill='currentColor'
			/>
		</svg>
	);
