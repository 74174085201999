export const ActionIcon = () => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_7425_11584)'>
			<path
				d='M10.6693 10.6666C11.7739 10.6666 12.6693 11.5619 12.6693 12.6666C12.6693 13.7713 11.7739 14.6666 10.6693 14.6666C9.5646 14.6666 8.66927 13.7713 8.66927 12.6666C8.66927 11.5619 9.5646 10.6666 10.6693 10.6666ZM4.0026 7.99992C5.47594 7.99992 6.66927 9.19325 6.66927 10.6666C6.66927 12.1399 5.47594 13.3333 4.0026 13.3333C2.52927 13.3333 1.33594 12.1399 1.33594 10.6666C1.33594 9.19325 2.52927 7.99992 4.0026 7.99992ZM10.6693 11.9999C10.3013 11.9999 10.0026 12.2986 10.0026 12.6666C10.0026 13.0346 10.3013 13.3333 10.6693 13.3333C11.0373 13.3333 11.3359 13.0346 11.3359 12.6666C11.3359 12.2986 11.0373 11.9999 10.6693 11.9999ZM4.0026 9.33325C3.26594 9.33325 2.66927 9.92992 2.66927 10.6666C2.66927 11.4033 3.26594 11.9999 4.0026 11.9999C4.73927 11.9999 5.33594 11.4033 5.33594 10.6666C5.33594 9.92992 4.73927 9.33325 4.0026 9.33325ZM9.66927 1.33325C11.6946 1.33325 13.3359 2.97459 13.3359 4.99992C13.3359 7.02525 11.6946 8.66659 9.66927 8.66659C7.64394 8.66659 6.0026 7.02525 6.0026 4.99992C6.0026 2.97459 7.64394 1.33325 9.66927 1.33325ZM9.66927 2.66659C8.3806 2.66659 7.33594 3.71125 7.33594 4.99992C7.33594 6.28859 8.3806 7.33325 9.66927 7.33325C10.9579 7.33325 12.0026 6.28859 12.0026 4.99992C12.0026 3.71125 10.9579 2.66659 9.66927 2.66659Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7425_11584'>
				<rect
					width='16'
					height='16'
					fill='currentColor'
				/>
			</clipPath>
		</defs>
	</svg>
);
