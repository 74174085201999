import { statusBgColorClassname } from 'BreetHelpers';

import Text from './Text';
import type { IStatusText } from './Text.helper';

const StatusText = ({ status, text, className }: IStatusText) => {
	const statusTextCustomClass = className ? ` ${className}` : '';

	return (
		<span className={`bt_statusText${statusBgColorClassname(status)}${statusTextCustomClass}`}>
			<Text
				as='span'
				variant='body_tiny'
			>
				{text ?? status}
			</Text>
		</span>
	);
};

export default StatusText;
