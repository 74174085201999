import type React from 'react';
import { useCallback, useEffect } from 'react';

interface UseClickOutsideType {
	targetElement?: React.RefObject<HTMLElement>;
	innerElements?: React.RefObject<HTMLElement>[];
	onClickOutsideClick?: () => void;
	onClickInsideClick?: () => void;
}

/**
 * Custom hook for handling click events outside a specified target element.
 * or inside an element
 *
 * @param {Object} options - The hook options.
 * @param {RefObject<HTMLDivElement>} options.targetElement - The target element ref for which click events will be monitored.
 * @param {RefObject<HTMLDivElement>[]} [options.innerElements] - Additional element refs for which click events will be monitored.
 * @param {Function} [options.onClickOutsideClick] - Callback function invoked when a click occurs outside the target element.
 * @param {Function} [options.onClickInsideClick] - Callback function invoked when a click occurs inside the target element.
 */
export const useClickOutside = ({
	targetElement,
	innerElements,
	onClickOutsideClick,
	onClickInsideClick,
}: UseClickOutsideType) => {
	// Event handler for mousedown events. Determines whether the click occurred inside or outside the target element.
	const clickOutside = useCallback(
		(e: MouseEvent) => {
			if (targetElement?.current?.contains(e.target as Node)) {
				onClickInsideClick?.();
				return;
			}

			if (innerElements?.some((ref) => ref.current?.contains(e.target as Node))) {
				onClickInsideClick?.();
				return;
			}

			onClickOutsideClick?.();
		},
		[innerElements, onClickInsideClick, onClickOutsideClick, targetElement]
	);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutside);
		return () => {
			document.removeEventListener('mousedown', clickOutside);
		};
	}, [clickOutside]);

	// This hook typically returns something related to the component using it.
	// You might want to return null or a callback if needed.
	return null;
};
