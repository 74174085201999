import type { RouteListItem } from 'BreetConfig';
import {
	accessMgtRouteList,
	siteMgtRouteList,
	userMgtMiscSubRouteList,
	userMgtRouteList,
	userMgtSubRouteList,
	withdrawalsRouteList,
	withdrawalsSubRouteList,
} from 'BreetConfig';

import {
	AccessMgtIcon,
	BillsIcon,
	DashboardIcon,
	InvoicesIcon,
	SiteMgtIcon,
	SwapCryptoIcon,
	TradesIcon,
	UserMgtIcon,
	WithdrawalsIcon,
} from '@/assets/icons/navbarIcons';

interface NavItemListType {
	key:
		| 'dashboard'
		| 'withdrawals'
		| 'trades'
		| 'swap-crypto'
		| 'access-management'
		| 'user-management'
		| 'bills'
		| 'crypto-invoice'
		| 'site-management'
		| 'reports'
		| 'appComponents';
	linkTitle: string;
	linkIcon: React.ReactNode;
	linkRef: string;
	activeState: string[];
	childRoutes?: RouteListItem[];
}

export const useNavitemList = () => {
	const navitemList: NavItemListType[] = [
		{
			key: 'dashboard',
			linkTitle: 'Dashboard',
			linkIcon: <DashboardIcon />,
			linkRef: '/dashboard',
			activeState: ['/dashboard'],
		},
		{
			key: 'withdrawals',
			linkTitle: 'Withdrawals',
			linkIcon: <WithdrawalsIcon />,
			linkRef: 'menu',
			activeState: ['/withdrawals/ngn', '/withdrawals/ghs', ...withdrawalsSubRouteList.map((route) => route.linkRef)],
			childRoutes: withdrawalsRouteList,
		},
		{
			key: 'trades',
			linkTitle: 'Trades',
			linkIcon: <TradesIcon />,
			linkRef: '/trades',
			activeState: ['/trades', '/trades/:txReference'],
		},
		{
			key: 'swap-crypto',
			linkTitle: 'Swap Crypto',
			linkIcon: <SwapCryptoIcon />,
			linkRef: '/swap-crypto',
			activeState: ['/swap-crypto', '/swap-crypto/:txReference'],
		},
		{
			key: 'bills',
			linkTitle: 'Bills',
			linkIcon: <BillsIcon />,
			linkRef: '/bills',
			activeState: ['/bills', '/bills/:txReference'],
		},
		{
			key: 'crypto-invoice',
			linkTitle: 'Crypto Invoices',
			linkIcon: <InvoicesIcon />,
			linkRef: '/crypto-invoice',
			activeState: ['/crypto-invoice', '/crypto-invoice/:txReference'],
		},
		{
			key: 'user-management',
			linkTitle: 'User Management',
			linkIcon: <UserMgtIcon />,
			linkRef: 'menu',
			activeState: [
				...userMgtRouteList.map((route) => route.linkRef),
				...userMgtSubRouteList.map((route) => route.linkRef),
				...userMgtMiscSubRouteList.map((route) => route.linkRef),
			],
			childRoutes: userMgtRouteList,
		},
		{
			key: 'access-management',
			linkTitle: 'Access Management',
			linkIcon: <AccessMgtIcon />,
			linkRef: '/access-management',
			activeState: ['/access-management', ...accessMgtRouteList.map((route) => route.linkRef)],
		},
		{
			key: 'site-management',
			linkTitle: 'Site Management',
			linkIcon: <SiteMgtIcon />,
			linkRef: 'menu',
			activeState: siteMgtRouteList.map((route) => route.linkRef),
			childRoutes: siteMgtRouteList,
		},
		{
			key: 'appComponents',
			linkTitle: 'App Components',
			linkIcon: <DashboardIcon />,
			linkRef: '/app-components',
			activeState: ['/app-components'],
		},
	];

	return navitemList;
};
