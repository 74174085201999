import type { IApiResponse } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const reportsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDashboardStats: builder.query<IApiResponse<Record<string, { total: number; count?: number; totalFiat?: number }>>, void>({
			query: () => '/admin/dash/stats',
		}),
	}),
});

export const { useGetDashboardStatsQuery } = reportsApiSlice;
