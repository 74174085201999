export const SearchIcon = () => (
	<svg
		width='14'
		height='14'
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M11.0233 10.0787L13.8786 12.9333L12.9353 13.8767L10.0806 11.0213C9.01843 11.8728 7.69727 12.3359 6.33594 12.334C3.02394 12.334 0.335938 9.64598 0.335938 6.33398C0.335938 3.02198 3.02394 0.333984 6.33594 0.333984C9.64794 0.333984 12.3359 3.02198 12.3359 6.33398C12.3379 7.69532 11.8748 9.01648 11.0233 10.0787ZM9.68594 9.58398C10.532 8.71391 11.0045 7.5476 11.0026 6.33398C11.0026 3.75532 8.91394 1.66732 6.33594 1.66732C3.75727 1.66732 1.66927 3.75532 1.66927 6.33398C1.66927 8.91198 3.75727 11.0007 6.33594 11.0007C7.54956 11.0026 8.71586 10.5301 9.58594 9.68398L9.68594 9.58398Z'
			fill='currentColor'
		/>
	</svg>
);
