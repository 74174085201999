import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DataInsight, Input, Text } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { deepMerge, formHasChanges, type ISellAssetType, spawnAppToast } from 'BreetHelpers';
import { useEditMode } from 'BreetHooks';
import { selectFxRates, selectInvoiceMeta, selectModals, useAppSelector, useUpdateAssetsRateMutation } from 'BreetRedux';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { EditIcon, MarkCorrectIcon } from '@/assets/icons';

import {
	CryptoAssetRateSchema,
	type CryptoAssetRateSchemaType,
} from '../../wallet-and-withdrawal/SiteMgtWalletAndWithdrawal.helpers';
import { generateRatePairs, type ViewEditAssetRateProps } from '../SiteMgtRate.helpers';

const ViewEditAssetRate = ({ invoice }: ViewEditAssetRateProps) => {
	const { editMode, toggleEditModeHandler } = useEditMode();

	const modals = useAppSelector(selectModals);
	const fxRates = useAppSelector(selectFxRates);
	const invoiceMeta = useAppSelector(selectInvoiceMeta);

	const [updateAssetRate, { isLoading }] = useUpdateAssetsRateMutation();

	const currentModal = modals.find((modal) => modal.id === 'viewEditCryotoAsset');
	const modalMetadata = currentModal?.metadata as { isEditing: boolean; data: ISellAssetType } | undefined;

	const pairedAssetRates = generateRatePairs(invoice?.rates ?? modalMetadata?.data.rates);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver<Record<number | string, number>>(CryptoAssetRateSchema),
	});

	const handleFormValueInitUpdate = useCallback(() => {
		setValue('minimum', invoice?.minimum ?? modalMetadata?.data.minimum ?? 0);
		setValue('confirmations', modalMetadata?.data.confirmations ?? 0);
		Object.entries(invoice?.rates ?? modalMetadata?.data.rates ?? {}).forEach(([dollarRange, ngnValue]) =>
			setValue(dollarRange, Number(ngnValue))
		);
	}, [invoice, modalMetadata, setValue]);

	useEffect(() => {
		handleFormValueInitUpdate();
	}, [handleFormValueInitUpdate]);

	const handleModalClose = useCallback(() => {
		closeModalById('viewEditCryotoAsset');
	}, []);

	const onSubmit = useCallback(
		(payload: unknown) => {
			const { minimum, confirmations, ...rest } = payload as CryptoAssetRateSchemaType;

			// Shaped payload related to Crypto rates
			const shapedCryptoPayload = {
				_id: modalMetadata?.data._id,
				minimum,
				confirmations,
				rates: { ...rest },
			};

			// Shaped payload related to invoice rates
			const shapedInvoicePayload = {
				rates: shapedCryptoPayload.rates,
				trading: {
					minimum: shapedCryptoPayload.minimum,
				},
			};

			const assetMeta = invoice ? invoiceMeta : modalMetadata?.data;
			const shapedPayload = invoice ? deepMerge(invoiceMeta, shapedInvoicePayload) : shapedCryptoPayload;

			const formHasChanged = formHasChanges({
				defaultValues: assetMeta,
				currentValues: { ...assetMeta, ...shapedPayload },
			});

			if (formHasChanged && invoice) {
				invoice.onInvoiceRateChange({
					payload: shapedInvoicePayload,
					onSuccess: toggleEditModeHandler,
				});
			}

			if (!formHasChanged && invoice) {
				toggleEditModeHandler();
			}

			if (formHasChanged && modalMetadata) {
				updateAssetRate({ assets: [shapedCryptoPayload] })
					.unwrap()
					.then((resp) => {
						spawnAppToast({ dataMsg: resp, type: 'success' });
						handleModalClose();
					})
					.catch((err: unknown) => {
						spawnAppToast({ dataMsg: err, type: 'error' });
					});
			}

			if (!formHasChanged && modalMetadata) {
				handleModalClose();
			}
		},
		[toggleEditModeHandler, handleModalClose, invoice, invoiceMeta, modalMetadata, updateAssetRate]
	);

	const currentValues = watch();
	const isEditMode = !!(invoice ? editMode : modalMetadata?.isEditing);

	return (
		<div className='assetRate'>
			{!!invoice && (
				<div className='assetRate_invoice'>
					<Text variant='body_default_bold'>Invoice Details</Text>
					<Button
						size='small'
						loading={invoice.isLoading}
						onClick={handleSubmit(onSubmit)}
						variant={isEditMode ? 'main' : 'gray'}
					>
						{isEditMode ? <MarkCorrectIcon type='double-check' /> : <EditIcon />}
						&nbsp;{isEditMode ? 'Save' : 'Edit'}
					</Button>
				</div>
			)}

			<div className='assetRate_topSect'>
				<Input
					prefixIcon='$'
					label='Minimum Value'
					size='small'
					type='number'
					wrapperClassName='assetRate_topSect__minimum'
					disabled={!isEditMode}
					register={register('minimum')}
					errorMsg={errors.minimum?.message}
				/>
				{!!modalMetadata && (
					<Input
						label='Confirmation Value'
						size='small'
						type='number'
						disabled={!isEditMode}
						register={register('confirmations')}
						errorMsg={errors.confirmations?.message}
					/>
				)}
			</div>

			<div className='assetRate_rates'>
				<DataInsight
					title='Rate Ranges'
					customValue={
						<div className='insight_group'>
							<div className='insight_group_col'>
								<Text variant='body_small_bold'>NGN Rate</Text>
							</div>
							<div className='insight_group_col'>
								<Text variant='body_small_bold'>GHS Rate</Text>
							</div>
						</div>
					}
				/>

				{pairedAssetRates?.map((assetRate, index, array) => {
					const isLastIndex = index === array.length - 1;
					const isFirstIndex = index === 0;
					let titleTxt = `$${assetRate.minValueInUsd} - $${assetRate.maxValueInUsd}`;
					if (isLastIndex) titleTxt = `Above $${assetRate.maxValueInUsd - 1}`;
					if (isFirstIndex) titleTxt = `Less than $${assetRate.maxValueInUsd + 1}`;

					if (typeof currentValues !== 'object') return null;

					const newRateInNgn = currentValues[Number(assetRate.key) as unknown as keyof typeof currentValues];
					const rateInGhs = newRateInNgn / (fxRates?.NGNToGHS ?? 1);

					return (
						<DataInsight
							key={assetRate.key}
							title={titleTxt}
							className='insight_group_input_wrapper'
							customValue={
								<div className='insight_group'>
									<div className='insight_group_col'>
										<Input
											size='small'
											type='number'
											disabled={!isEditMode}
											register={register(assetRate.key)}
											errorMsg={errors[assetRate.key]?.message}
										/>
									</div>
									<div className='insight_group_col'>
										<Input
											size='small'
											disabled
											extValue={rateInGhs.toString()}
										/>
									</div>
								</div>
							}
						/>
					);
				})}
			</div>

			{modalMetadata && isEditMode && (
				<div className='assetRate_cta'>
					<Button
						variant='gray'
						onClick={handleModalClose}
					>
						Cancel
					</Button>
					<Button
						variant='blue'
						loading={isLoading}
						onClick={handleSubmit(onSubmit)}
					>
						Save Changes
					</Button>
				</div>
			)}
		</div>
	);
};

export default ViewEditAssetRate;
