import type { ISellAssetRateType } from 'BreetHelpers';

export interface InvoiceRateChangeType {
	payload: unknown;
	onSuccess: () => void;
}

export interface ViewEditAssetRateProps {
	invoice?: {
		isLoading: boolean;
		minimum?: number;
		rates?: ISellAssetRateType;
		onInvoiceRateChange: (option: InvoiceRateChangeType) => void;
	};
}

export const generateRatePairs = (rateObj?: ISellAssetRateType) => {
	if (!rateObj || !Object.keys(rateObj).length) return undefined;

	return Object.entries(rateObj).map(([key, value], index, array) => {
		const nextKey = Number(array[index + 1]?.[0]);
		return {
			key,
			minValueInUsd: Number(key),
			maxValueInUsd: nextKey ? nextKey - 1 : Number(key),
			rateInNgn: value as number,
		};
	});
};
