import type { IUser } from 'BreetHelpers';
import { mapArrayFromLength } from 'BreetHelpers';
import { Link } from 'react-router-dom';

import { StarRating } from '@/assets/icons';

import { Text } from '../Text';

const UserTableColumn = ({ username, _id, level, tag, withLevel = false }: Partial<IUser & { withLevel: boolean }>) => {
	const usernameNoteClass = tag?.status ? ' withNote' : '';

	return (
		<Link
			to={`/user-management/users/${_id}/details`}
			className='userTableColumn'
		>
			<Text
				variant='body_small_bold'
				className={`username${usernameNoteClass}`}
			>
				{username}
			</Text>
			<div className='userRating'>
				{level !== undefined && withLevel
					? mapArrayFromLength(5).map((rating) => (
							<StarRating
								key={rating}
								type={level >= rating ? 'filled' : 'default'}
							/>
						))
					: null}
			</div>
		</Link>
	);
};

export default UserTableColumn;
