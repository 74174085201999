import type { BaseQueryApi, FetchArgs } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { getLocationFrom } from 'BreetHelpers';

const baseQuery = fetchBaseQuery({
	baseUrl: '/proxy',
	headers: {
		'x-breet-env': import.meta.env.VITE_BREET_ENV,
	},
	credentials: 'include',
});

// create a new mutex
const mutex = new Mutex();

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: object) => {
	// wait until the mutex is available without locking it
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);

	// Reject promise if usual error
	if (result.error && result.error.status === 401) {
		/*
		 * When response code is 401, try to refresh the token.
		 * token refresh causes the 401 response
		 */

		// checking whether the mutex is locked
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();

			try {
				/*
				 * When response code is 401, try to refresh the token.
				 * token refresh causes the 401 response
				 */
				// try to get a new token
				const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);
				if (refreshResult.data) {
					// retry the initial query
					result = await baseQuery(args, api, extraOptions);
				} else {
					throw new Error();
				}
			} catch (_e: unknown) {
				// if response is 401 refresh token has expired and user session terminated,
				// logout user
				window.location.replace(`/getlost?from=${getLocationFrom()}&sessionTimeout=true`);
			} finally {
				// release must be called once the mutex should be released again.
				release();
			}
		} else {
			// wait until the mutex is available without locking it
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}
	return result;
};

export const apiSlice = createApi({
	reducerPath: 'api',
	baseQuery: baseQueryWithReauth,
	endpoints: () => ({}),
	tagTypes: [
		'User',
		'DashboardStats',
		'GetAllUsers',
		'SingleUser',
		'GetAllWithdrawals',
		'GetUserWithdrawals',
		'GetSingleWithdrawal',
		'GetSellTrades',
		'GetAllSellAssets',
		'GetUserSellTrades',
		'GetUserNoteHistory',
		'GetSingleSellTrade',
		'GetInvoices',
		'GetUserInvoices',
		'GetSingleInvoice',
		'GetUserWallets',
		'GetAllUsersWallets',
		'GetWalletDetails',
		'GetAllUserBanks',
		'GetUserBanks',
		'GetBankDetails',
		'GetUserRewards',
		'GetKycs',
		'GetKycDetails',
		'GetUserKycDetails',
		'GetAggregatedBanks',
		'GetAllUserReferrals',
		'GetFlashMessages',
		'GetFeaturedNotes',
		'GetBanners',
	],
});
