export const DashboardIcon = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_7161_3816)'>
			<path
				d='M13 21V11H21V21H13ZM3 13V3H11V13H3ZM9 11V5H5V11H9ZM3 21V15H11V21H3ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 3H21V9H13V3ZM15 5V7H19V5H15Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7161_3816'>
				<rect
					width='24'
					height='24'
					fill='currentColor'
				/>
			</clipPath>
		</defs>
	</svg>
);
