import { capitalizeFirstLetter, ISettingsSupportLine } from 'BreetHelpers';
import * as yup from 'yup';

const createEditSupportSchema = () =>
	yup.object({
		isEnabled: yup.bool().required('isEnabled is reuqired'),
		value: yup.string().required('value is reuqired'),
	});

export const EditSupportLineSchema: yup.ObjectSchema<ISettingsSupportLine> = yup.object({
	callLine: createEditSupportSchema(),
	whatsAppLine: createEditSupportSchema(),
	whatsAppLink: createEditSupportSchema(),
	telegramLink: createEditSupportSchema(),
	instagramLink: createEditSupportSchema(),
	xLink: createEditSupportSchema(),
	email: createEditSupportSchema(),
});

export type EditSupportLineSchemaKeyType = keyof ISettingsSupportLine;

export const getSupportLineTitle = (line: string): string => {
	// Special handling for strings starting with 'whatsApp'
	if (line.startsWith('whatsApp')) {
		return `WhatsApp ${capitalizeFirstLetter(line.slice(8))}`;
	}

	// Split the camelCase string into words and capitalize each word
	return line
		.split(/(?=[A-Z])/)
		.map(capitalizeFirstLetter)
		.join(' ');
};
