export const FilterIcon = () => (
	<svg
		width='12'
		height='13'
		viewBox='0 0 12 13'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.33317 7.33333L7.33317 11.3333L4.6665 12.6667V7.33333L0.666504 1.33333L0.666504 0L11.3332 0V1.33333L7.33317 7.33333ZM2.26917 1.33333L5.99984 6.92933L9.7305 1.33333L2.26917 1.33333Z'
			fill='currentColor'
		/>
	</svg>
);
