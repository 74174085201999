import type { IApiPaginatedFilterQuery, IApiResponse, IApiResponsePaginated, IUserRewards } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const rewardsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllUsersRewards: builder.query<IApiResponsePaginated<IUserRewards[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/payments/rewards', params }),
		}),
		getUserRewards: builder.query<IApiResponsePaginated<IUserRewards[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId, ...params }) => ({ url: `/admin/payments/user/${userId}/rewards`, params }),
			providesTags: ['GetUserRewards'],
		}),
		getRewardDetails: builder.query<IApiResponse<IUserRewards>, string | undefined>({
			query: (txReference) => ({ url: `/admin/payments/reward/${txReference}` }),
		}),
		creditUserRewardPoints: builder.mutation<IApiResponse, Record<string, string | boolean | number>>({
			query: ({ userId, points, reason }) => ({
				method: 'PUT',
				url: `/admin/payments/user/${userId}/reward`,
				body: { points, reason },
			}),
			invalidatesTags: ['SingleUser'],
		}),
		debitUserRewardPoints: builder.mutation<IApiResponse, Record<string, string | boolean | number>>({
			query: ({ userId, points, reason }) => ({
				method: 'PATCH',
				url: `/admin/payments/user/${userId}/reward`,
				body: { points, reason },
			}),
			invalidatesTags: ['SingleUser'],
		}),
	}),
});

export const {
	useGetAllUsersRewardsQuery,
	useGetUserRewardsQuery,
	useGetRewardDetailsQuery,
	useCreditUserRewardPointsMutation,
	useDebitUserRewardPointsMutation,
} = rewardsApiSlice;
