import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useCreditUserRewardPointsMutation, useDebitUserRewardPointsMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import type { AddRemoveUserPointSchemaType } from './userActions.helpers';
import { AddRemoveUserPointSchema } from './userActions.helpers';

const AddOrRemoveUserPointsBalance = () => {
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modal.id === 'addRemoveUserPointBalance');
	const modalMetadata = currentModal?.metadata as Record<string, string | number | Record<string, string | number>> | undefined;
	const isAddingPoints = !!modalMetadata?.isAdding;
	const userId = modalMetadata?.userId as string;
	const rewardPoints = modalMetadata?.rewardPoints as Record<string, string>;

	const [creditUser, { isLoading: isCreditingUser }] = useCreditUserRewardPointsMutation();
	const [debitUser, { isLoading: isDebitingUser }] = useDebitUserRewardPointsMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(AddRemoveUserPointSchema),
	});

	const onSubmit = useCallback(
		(payload: AddRemoveUserPointSchemaType) => {
			if (isAddingPoints) {
				creditUser({ userId, ...payload })
					.unwrap()
					.then((resp) => {
						spawnAppToast({ dataMsg: resp, type: 'success' });
						closeModalById('addRemoveUserPointBalance');
					})
					.catch((err: unknown) => {
						spawnAppToast({ dataMsg: err, type: 'error' });
					});
			} else {
				debitUser({ userId, ...payload })
					.unwrap()
					.then((resp) => {
						spawnAppToast({ dataMsg: resp, type: 'success' });
						closeModalById('addRemoveUserPointBalance');
					})
					.catch((err: unknown) => {
						spawnAppToast({ dataMsg: err, type: 'error' });
					});
			}
		},
		[creditUser, debitUser, isAddingPoints, userId]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Input
				label='Current Points Balance'
				placeholder='0.00'
				extValue={rewardPoints.balance}
				disabled
			/>
			<Input
				label={`${isAddingPoints ? 'Add to' : 'Remove'} Balance`}
				placeholder='0.00'
				register={register('points')}
				errorMsg={errors.points?.message}
			/>
			<TextareaInput
				label='Description'
				placeholder='Description'
				rows={3}
				register={register('reason')}
				errorMsg={errors.reason?.message}
			/>
			<Button
				size='large'
				variant={isAddingPoints ? 'main' : 'red'}
				loading={isCreditingUser || isDebitingUser}
			>
				{isAddingPoints ? 'Add' : 'Remove'}
			</Button>
		</form>
	);
};

export default AddOrRemoveUserPointsBalance;
