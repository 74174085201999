import { matchStyle } from 'BreetHelpers';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import { Text } from '../Text';
import type { IInput } from './Input.heper';

const Input = forwardRef<HTMLInputElement, IInput>(
	(
		{
			id,
			wrapperClassName,
			prefixIcon,
			suffixIcon,
			onChange,
			size = 'regular',
			widthType = 'default',
			type = 'text',
			placeholder,
			errorMsg,
			register,
			label,
			customLabel,
			extValue,
			...rest
		},
		ref
	) => {
		const [inputValue, setInputValue] = useState<string>('');

		const inputId = placeholder?.split(' ').join('-');

		useEffect(() => {
			if (extValue !== undefined) setInputValue(extValue);
		}, [extValue]);

		const onInputChangeHandler = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				setInputValue(e.target.value);
				if (onChange) onChange(e.target.value, e);
				register?.onChange({ target: e.target, type: e.type }).catch((err: unknown) => {
					console.error(err);
				});
			},
			[onChange, register]
		);

		const wrapClassName = matchStyle(wrapperClassName, {
			[`${wrapperClassName}`]: ` ${wrapperClassName}`,
			default: '',
		});
		const errorMsgClassName = errorMsg ? ' errorInput' : '';

		const renderInputLabel = useCallback(() => {
			if (customLabel ?? label)
				return (
					<Text
						variant='body_small_bold'
						className='bt_input_label'
						as='label'
						htmlFor={id ?? inputId}
					>
						{customLabel ?? label}
					</Text>
				);

			return null;
		}, [customLabel, id, inputId, label]);

		return (
			<div className={`bt_input_contain_wrapper${errorMsgClassName}`}>
				{renderInputLabel()}

				<div className={`bt_input_contain ${size} ${widthType}${wrapClassName}`}>
					{prefixIcon && <span className='bt_input_prefixIcon'>{prefixIcon}</span>}
					<input
						id={id ?? inputId}
						ref={ref}
						placeholder={placeholder}
						type={type}
						value={extValue ? inputValue : undefined}
						{...register}
						onChange={onInputChangeHandler}
						{...rest}
					/>
					{suffixIcon && <span className='bt_input_suffixIcon'>{suffixIcon}</span>}
				</div>
				{errorMsg ? (
					<Text
						className='bt_input_error'
						variant='body_small_bold'
						as='span'
					>
						{errorMsg}
					</Text>
				) : null}
			</div>
		);
	}
);

export default Input;

Input.displayName = 'BreetInput';
