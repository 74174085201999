import { useEffect, useState } from 'react';

/**
 * Custom Hook: useTimer
 *
 * @param {number} initialSeconds - Initial duration of the timer in milliseconds.
 * @returns {object} - Timer-related state and functions.
 */
export const useTimer = (initialSeconds?: number, callback?: () => void) => {
	const [milliseconds, setMilliseconds] = useState(initialSeconds ?? 60000);
	const [isRunning, setIsRunning] = useState(true);

	useEffect(() => {
		let intervalId: NodeJS.Timeout | undefined;

		if (isRunning && milliseconds > 0) {
			intervalId = setInterval(() => {
				setMilliseconds((prevSeconds) => Math.max(0, prevSeconds - 1000));
			}, 1000);
		} else {
			clearInterval(intervalId);
			if (callback) callback();
		}
		return () => clearInterval(intervalId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRunning, milliseconds]);

	const resetTimer = () => {
		setMilliseconds(initialSeconds ?? 60000);
	};

	const pauseTimer = () => {
		setIsRunning(false);
	};

	const resumeTimer = () => {
		setIsRunning(true);
	};

	return {
		seconds: milliseconds / 1000,
		isRunning,
		resetTimer,
		pauseTimer,
		resumeTimer,
	};
};
