interface Props {
	type?: 'arrowDown' | 'default' | 'rightCaret' | 'leftCaret';
}

export const ArrowIcon = ({ type }: Props) => {
	if (type === 'rightCaret') {
		return (
			<svg
				width='6'
				height='10'
				viewBox='0 0 6 10'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M3.78438 5.00048L0.484375 1.70048L1.42704 0.757812L5.66971 5.00048L1.42704 9.24315L0.484375 8.30048L3.78438 5.00048Z'
					fill='currentColor'
				/>
			</svg>
		);
	}
	if (type === 'leftCaret') {
		return (
			<svg
				width='6'
				height='10'
				viewBox='0 0 6 10'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M2.22127 5.00048L5.52127 8.30048L4.5786 9.24315L0.335938 5.00048L4.5786 0.757812L5.52127 1.70048L2.22127 5.00048Z'
					fill='currentColor'
				/>
			</svg>
		);
	}
	if (type === 'arrowDown') {
		return (
			<svg
				width='10'
				height='6'
				viewBox='0 0 10 6'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M5.00048 3.78047L8.30048 0.480469L9.24315 1.42314L5.00048 5.6658L0.757812 1.42314L1.70048 0.480469L5.00048 3.78047Z'
					fill='currentColor'
				/>
			</svg>
		);
	}
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_6952_45306)'>
				<path
					d='M13.4768 9.16591L9.00683 4.69591L10.1852 3.51758L16.6668 9.99924L10.1852 16.4809L9.00683 15.3026L13.4768 10.8326H3.3335V9.16591H13.4768Z'
					fill='#565F7D'
				/>
			</g>
			<defs>
				<clipPath id='clip0_6952_45306'>
					<rect
						width='20'
						height='20'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
