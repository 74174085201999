import Lottie from 'lottie-react';

import defaultAnimationData from '@/assets/lottie/loading.json';

const Loader = ({ animationData }: { animationData?: unknown }) => (
	<div className='bt_baseLoader'>
		<Lottie animationData={animationData ?? defaultAnimationData} />
	</div>
);

export default Loader;
