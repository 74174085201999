export const LoaderIcon = () => (
	<svg
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M0.0556877 10.0005H2.07069C2.31298 11.6652 3.14656 13.1871 4.41892 14.2877C5.69129 15.3883 7.31737 15.994 8.99969 15.994C10.682 15.994 12.3081 15.3883 13.5805 14.2877C14.8528 13.1871 15.6864 11.6652 15.9287 10.0005H17.9447C17.6982 12.2003 16.6496 14.2322 14.9995 15.7077C13.3493 17.1833 11.2133 17.999 8.99969 17.999C6.78606 17.999 4.65005 17.1833 2.99991 15.7077C1.34977 14.2322 0.30123 12.2003 0.0546875 10.0005H0.0556877ZM0.0556877 8.00047C0.302231 5.80061 1.35077 3.76874 3.00091 2.29321C4.65105 0.817672 6.78706 0.00195312 9.00069 0.00195312C11.2143 0.00195312 13.3503 0.817672 15.0005 2.29321C16.6506 3.76874 17.6992 5.80061 17.9457 8.00047H15.9307C15.6884 6.33569 14.8548 4.81379 13.5825 3.7132C12.3101 2.61261 10.684 2.00691 9.00169 2.00691C7.31937 2.00691 5.69328 2.61261 4.42092 3.7132C3.14856 4.81379 2.31498 6.33569 2.07269 8.00047H0.0556877Z'
			fill='currentColor'
		/>
	</svg>
);
