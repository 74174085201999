import { removeToast, selectToasts, useAppDispatch, useAppSelector } from 'BreetRedux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Toast from './Toast';

const ToastWrapper = () => {
	const [mounted, setMounted] = useState(false);
	const [toastsToBeClosed, setToastsToBeClosed] = useState<string[]>([]);

	const toasts = useAppSelector(selectToasts);
	const dispatch = useAppDispatch();
	const toastElWrapperRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		toastElWrapperRef.current = document.createElement('div');
		setMounted(true);
		const toastRoot = document.getElementById('bt_toast_root');

		const el = toastElWrapperRef.current;
		toastRoot?.appendChild(el);
		return () => {
			toastRoot?.removeChild(el);
		};
	}, []);

	const removeToastFromStore = useCallback(
		(toastId: string) => {
			dispatch(removeToast(toastId));
			setToastsToBeClosed((prevData) => prevData.filter((itemId) => itemId !== toastId));
		},
		[dispatch]
	);

	const handleCloseToast = useCallback(
		(toastId: string) => {
			setToastsToBeClosed((prevData) => [...prevData, toastId]);
			setTimeout(() => removeToastFromStore(toastId), 300);
		},
		[removeToastFromStore]
	);

	return mounted && toastElWrapperRef.current
		? createPortal(
				<div className='bt_toastWrapper'>
					{toasts.map((toast) => (
						<Toast
							key={toast.id}
							id={toast.id}
							closeToast={toastsToBeClosed.includes(toast.id)}
							duration={toast.duration}
							title={toast.title}
							message={toast.message}
							type={toast.type}
							onClose={handleCloseToast}
						/>
					))}
				</div>,
				toastElWrapperRef.current
			)
		: null;
};

export default ToastWrapper;
