import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type {
	IAggregratedBank,
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	ICmsBanner,
	IFlashNoteMsg,
	ISettings,
	ISettingsObjKey,
	IUploadedFile,
	IUploadFile,
	IUserReferral,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const siteMgtApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateSettings: builder.mutation<IApiResponse<ISettings>, { key: ISettingsObjKey; value: unknown }>({
			query: ({ key, value }) => ({ url: '/admin/settings/update', body: { [key]: value }, method: 'PUT' }),
			invalidatesTags: ['User'],
		}),
		getAggregatedBanks: builder.query<IApiResponsePaginated<IAggregratedBank[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/payments/banks/aggregate', params }),
			providesTags: ['GetAggregatedBanks'],
		}),
		updateAggregatedBank: builder.mutation<IApiResponse, { enable: boolean; bankId: string }>({
			query: ({ enable, bankId }) => ({
				url: '/admin/payments/bank/update',
				params: { bankId },
				method: enable ? 'PUT' : 'PATCH',
			}),
			invalidatesTags: ['GetAggregatedBanks'],
		}),
		updateReferral: builder.mutation<IApiResponse, Partial<IUserReferral> & { referralId: string }>({
			query: ({ referralId, ...body }) => ({ url: `/admin/settings/referrals/${referralId}`, method: 'PUT', body }),
			invalidatesTags: ['GetAllUserReferrals', 'SingleUser'],
		}),
		getFlashMessages: builder.query<IApiResponse<IFlashNoteMsg>, void>({
			query: () => ({ url: '/admin/settings/flash' }),
			providesTags: ['GetFlashMessages'],
		}),
		updateFlashMessage: builder.mutation<IApiResponse<IFlashNoteMsg>, Partial<IFlashNoteMsg>>({
			query: (body) => ({ url: '/admin/settings/flash', method: 'POST', body }),
			invalidatesTags: ['GetFlashMessages'],
		}),
		getFeaturedNotes: builder.query<IApiResponse<IFlashNoteMsg[]>, void>({
			query: () => ({ url: '/admin/settings/notes' }),
			providesTags: ['GetFeaturedNotes'],
		}),
		updateFeaturedNote: builder.mutation<IApiResponse, Partial<IFlashNoteMsg>>({
			query: (body) => ({ url: '/admin/settings/notes', method: 'POST', body }),
			invalidatesTags: ['GetFeaturedNotes'],
		}),
		getBanners: builder.query<IApiResponse<ICmsBanner[]>, void>({
			query: () => ({ url: '/admin/settings/banners' }),
			providesTags: ['GetBanners'],
		}),
		updateBanner: builder.mutation<IApiResponse<ICmsBanner[]>, Record<string, string | undefined>[]>({
			query: (body) => ({ url: '/admin/settings/banner', method: 'POST', body: body[0] }), // Temporary workaround till BE is updated
			invalidatesTags: ['GetBanners'],
		}),
		uploadAttachments: builder.mutation<IApiResponse<IUploadedFile[]>, IUploadFile[]>({
			queryFn: async (arg, _queryApi, _extraOptions, fetchWithBQ) => {
				try {
					let urls: IUploadedFile[] = [];

					const response = await fetchWithBQ({ url: '/admin/upload', method: 'PUT', body: { files: arg } });
					if (response.error) {
						return { error: response.error };
					}

					urls = (response.data as IApiResponse<IUploadedFile[]> | undefined)?.data ?? [];

					const container: (() => Promise<Response>)[] = [];
					if (urls.length) {
						urls.forEach((el, i) => {
							container.push(() =>
								fetch(el.url, {
									method: 'PUT',
									headers: {
										'x-amz-acl': 'public-read',
										'Content-Type': arg[i].type,
									},
									body: arg[i].image,
								})
							);
						});
					}

					await Promise.all(container.map((fn) => fn())).catch(() => {
						throw new Error('Failed to upload attachments');
					});

					return { data: { data: urls } as unknown as IApiResponse<IUploadedFile[]> };
				} catch (error) {
					return { error: error as FetchBaseQueryError };
				}
			},
		}),
	}),
});

export const {
	useUpdateSettingsMutation,
	useGetAggregatedBanksQuery,
	useUpdateAggregatedBankMutation,
	useUpdateReferralMutation,
	useGetFlashMessagesQuery,
	useUpdateFlashMessageMutation,
	useGetFeaturedNotesQuery,
	useUpdateFeaturedNoteMutation,
	useGetBannersQuery,
	useUpdateBannerMutation,
	useUploadAttachmentsMutation,
} = siteMgtApiSlice;
