export const TradesIcon = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_7161_3832)'>
			<path
				d='M19.3741 15.1035C20.0229 13.5618 20.1723 11.8555 19.8011 10.2245C19.43 8.59352 18.557 7.11987 17.305 6.01073C16.0529 4.90159 14.4847 4.21274 12.8209 4.04104C11.1571 3.86933 9.48124 4.2234 8.02906 5.05347L7.03706 3.31647C8.5547 2.44909 10.2733 1.99484 12.0213 1.99905C13.7693 2.00326 15.4856 2.46579 16.9991 3.34047C21.4891 5.93247 23.2091 11.4825 21.1161 16.1105L22.4581 16.8845L18.2931 19.0985L18.1281 14.3845L19.3741 15.1035ZM4.62406 8.89747C3.97521 10.4392 3.82585 12.1455 4.19698 13.7764C4.56811 15.4074 5.44108 16.8811 6.69314 17.9902C7.9452 19.0994 9.51338 19.7882 11.1772 19.9599C12.8411 20.1316 14.5169 19.7775 15.9691 18.9475L16.9611 20.6845C15.4434 21.5519 13.7249 22.0061 11.9769 22.0019C10.2288 21.9977 8.5125 21.5352 6.99906 20.6605C2.50906 18.0685 0.789062 12.5185 2.88206 7.89047L1.53906 7.11747L5.70406 4.90347L5.86906 9.61747L4.62306 8.89847L4.62406 8.89747ZM13.4141 14.8285L10.5831 12.0005L7.75506 14.8285L6.34106 13.4145L10.5841 9.17247L13.4131 12.0005L16.2421 9.17247L17.6561 10.5865L13.4131 14.8285H13.4141Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7161_3832'>
				<rect
					width='24'
					height='24'
					fill='currentColor'
				/>
			</clipPath>
		</defs>
	</svg>
);
