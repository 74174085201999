import type { SkeletonLoaderProps } from './Loader.helper';

const SkeletonLoader = ({ type = 'paragraph', className, width = '10rem', height = '10rem' }: SkeletonLoaderProps) => {
	const skeletonClass = className ? `bt_skeleton ${className}` : 'bt_skeleton';

	if (type === 'circle') {
		return (
			<div
				className={skeletonClass}
				style={{ width, height: width }}
			>
				<div className='bt_skeleton__type_circle bt_skeleton__type_square' />
			</div>
		);
	}
	if (type === 'square') {
		return (
			<div
				className={skeletonClass}
				style={{ width, height }}
			>
				<div className='bt_skeleton__type_square' />
			</div>
		);
	}

	return (
		<div
			className={`${skeletonClass} paragraph`}
			style={{ height }}
		>
			<div className='bt_skeleton__type_line' />
		</div>
	);
};
export default SkeletonLoader;
