interface Props {
	type?: 'filled' | 'default';
}

export const StarRating = ({ type }: Props) =>
	type === 'filled' ? (
		<svg
			width='8'
			height='8'
			viewBox='0 0 8 8'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.99965 6.0865L1.64865 7.4025L2.17365 4.75984L0.195312 2.9305L2.87098 2.61317L3.99965 0.166504L5.12831 2.61317L7.80398 2.9305L5.82565 4.75984L6.35065 7.4025L3.99965 6.0865Z'
				fill='#F59E0B'
			/>
		</svg>
	) : (
		<svg
			width='8'
			height='8'
			viewBox='0 0 8 8'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.99965 6.0865L1.64865 7.4025L2.17365 4.75984L0.195312 2.9305L2.87098 2.61317L3.99965 0.166504L5.12831 2.61317L7.80398 2.9305L5.82565 4.75984L6.35065 7.4025L3.99965 6.0865ZM3.99965 5.3225L5.41531 6.11484L5.09898 4.52384L6.28998 3.42217L4.67898 3.23117L3.99965 1.75817L3.32031 3.2315L1.70931 3.42217L2.90031 4.52384L2.58398 6.11484L3.99965 5.3225Z'
				fill='#F59E0B'
			/>
		</svg>
	);
