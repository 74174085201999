import type { RouteObject } from 'react-router-dom';

const accessMgtRoutes: RouteObject[] = [
	{
		path: 'access-management',
		children: [
			{
				index: true,
				lazy: () => import('../features/access-management'),
			},
			{
				path: 'create-admin',
				lazy: () => import('../features/access-management/create-admin'),
			},
			{
				path: ':adminId/view',
				lazy: () => import('../features/access-management/[adminId]/view'),
			},
			{
				path: ':adminId/edit',
				lazy: () => import('../features/access-management/[adminId]/edit'),
			},
			{
				path: ':adminId/edit-privilege',
				lazy: () => import('../features/access-management/[adminId]/edit-privilege'),
			},
		],
	},
];

export default accessMgtRoutes;
