import type { RouteObject } from 'react-router-dom';

const withdrawalRoutes: RouteObject[] = [
	{
		path: 'withdrawals',
		children: [
			{
				path: 'ghs',
				lazy: () => import('../features/withdrawals/ghs'),
			},
			{
				path: 'ghs/:txReference/:txStatus',
				lazy: () => import('../features/withdrawals/ghs/[txStatus]'),
			},
			{
				path: 'ngn',
				lazy: () => import('../features/withdrawals/ngn'),
			},
			{
				path: 'ngn/:txReference/:txStatus',
				lazy: () => import('../features/withdrawals/ngn/[txStatus]'),
			},
		],
	},
];

export default withdrawalRoutes;
