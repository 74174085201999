import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import type { StatusColorOptionType } from 'BreetHelpers';

export interface ToastState {
	id: string;
	title?: string;
	message?: string;
	duration?: number;
	type: StatusColorOptionType;
}

const initialState: ToastState[] = [];

export const toastSlice = createSlice({
	name: 'toasts',
	initialState,
	reducers: (create) => ({
		addToast: create.reducer((state, action: PayloadAction<Omit<ToastState, 'id'>>) => {
			const newToast = {
				id: nanoid(),
				...action.payload,
			};
			state.push(newToast);
		}),
		removeToast: create.reducer((state, action: PayloadAction<string>) => {
			const filteredState = state.filter((item) => item.id !== action.payload);
			return filteredState;
		}),
	}),
	selectors: {
		selectToasts: (state) => state,
	},
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;

export const { selectToasts } = toastSlice.selectors;
