import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { apiSlice, appSlice, modalsSlice, toastSlice } from './slices';

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const reducer = combineSlices(apiSlice, appSlice, modalsSlice, toastSlice);
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof reducer>;

export const makeStore = (preloadedState?: Partial<RootState>) => {
	const storeConfig = configureStore({
		reducer,
		preloadedState,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
	});
	// configure listeners using the provided defaults
	// optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
	setupListeners(storeConfig.dispatch);
	return storeConfig;
};

export const store = makeStore();

// Infer the type of makeStore
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
