import { Button } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useDeleteSingleUserMutation } from 'BreetRedux';
import { useCallback } from 'react';

const DeleteUser = () => {
	const modals = useAppSelector(selectModals);

	const [deleteSingleUser, { isLoading }] = useDeleteSingleUserMutation();

	const currentModal = modals.find((modal) => modal.id === 'deleteSingleUser');
	const modalMetadata = currentModal?.metadata as Record<string, string> | undefined;

	const handleDeleteUserClick = useCallback(() => {
		if (!modalMetadata?.userId) return;

		deleteSingleUser(modalMetadata.userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
				closeModalById('deleteSingleUser');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [deleteSingleUser, modalMetadata?.userId]);

	return (
		<Button
			size='large'
			variant='red'
			loading={isLoading}
			onClick={handleDeleteUserClick}
		>
			Delete
		</Button>
	);
};

export default DeleteUser;
