interface Props {
	type?: 'default' | 'double-check';
}

export const MarkCorrectIcon = ({ type }: Props) =>
	type === 'double-check' ? (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_7933_25577)'>
				<path
					d='M7.73206 9.17366L8.6734 10.115L14.3174 4.47099L15.2601 5.41366L8.6734 12.0003L4.43073 7.75766L5.3734 6.81499L6.79006 8.23166L7.73206 9.17299V9.17366ZM7.7334 7.28833L11.0347 3.98633L11.9747 4.92633L8.6734 8.22833L7.7334 7.28833ZM5.84873 11.0583L4.90673 12.0003L0.664062 7.75766L1.60673 6.81499L2.54873 7.75699L2.54806 7.75766L5.84873 11.0583Z'
					fill='currentColor'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7933_25577'>
					<rect
						width='16'
						height='16'
						fill='currentColor'
					/>
				</clipPath>
			</defs>
		</svg>
	) : (
		<svg
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_7511_24760)'>
				<path
					d='M6.66454 10.115L12.7925 3.98633L13.7359 4.92899L6.66454 12.0003L2.42187 7.75766L3.36454 6.81499L6.66454 10.115Z'
					fill='currentColor'
				/>
			</g>
			<defs>
				<clipPath id='clip0_7511_24760'>
					<rect
						width='16'
						height='16'
						fill='currentColor'
					/>
				</clipPath>
			</defs>
		</svg>
	);
