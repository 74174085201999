import { useCallback, useMemo, useState } from 'react';

import type { ITable } from './Table.helper';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';

const Table = <E extends { _id?: string }>({ data, columns, isLoading, onCheckboxChange, ...rest }: ITable<E>) => {
	const [checkedItems, setCheckedItems] = useState<E[]>([]);

	const isColumnCheckboxDisabled = useMemo(() => !data?.length, [data]);
	const filteredColumns = useMemo(() => columns.filter((column) => !column.hideColumn), [columns]);

	const columnCheckboxHandler = useCallback(() => {
		if (isColumnCheckboxDisabled || !data) return;
		if (checkedItems.length === data.length) {
			setCheckedItems([]);
			onCheckboxChange?.([]);
		} else {
			setCheckedItems(data);
			onCheckboxChange?.(data);
		}
	}, [checkedItems.length, data, isColumnCheckboxDisabled, onCheckboxChange]);

	const rowCheckboxToggleHandler = useCallback(
		(rowItem: E) => {
			if (isColumnCheckboxDisabled) return;
			const existingItem = checkedItems.find((item) => item._id === rowItem._id);
			if (existingItem) {
				setCheckedItems((prevData) => prevData.filter((item) => item._id !== rowItem._id));
				onCheckboxChange?.(checkedItems.filter((item) => item._id !== rowItem._id));
			} else {
				setCheckedItems((prevData) => [...prevData, rowItem]);
				onCheckboxChange?.([...checkedItems, rowItem]);
			}
		},
		[checkedItems, isColumnCheckboxDisabled, onCheckboxChange]
	);

	return (
		<div className='bt_table__wrapper'>
			<table
				className='bt_table__wrapper_contain'
				cellSpacing={0}
			>
				<thead>
					<TableHeader
						withCheckbox={!!onCheckboxChange}
						columns={filteredColumns}
						checkedItems={checkedItems}
						dataLength={data?.length ?? 0}
						isLoading={isLoading}
						onColumnCheckToggle={columnCheckboxHandler}
						useCheckBox={!!onCheckboxChange}
					/>
				</thead>
				<tbody>
					<TableBody
						{...rest}
						data={data}
						columns={filteredColumns}
						isLoading={isLoading}
						withCheckbox={!!onCheckboxChange}
						checkedItems={checkedItems}
						onRowCheckToggle={rowCheckboxToggleHandler}
						useCheckBox={!!onCheckboxChange}
					/>
				</tbody>
			</table>
		</div>
	);
};

export default Table;

Table.displayName = 'BreetTable';
