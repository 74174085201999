import { Text } from 'BreetComponents';
import { useCallback, useEffect, useRef, useState } from 'react';

const CollapsibleNavItem = ({ children, header }: React.PropsWithChildren<{ header: React.ReactNode }>) => {
	const [isOpen, setIsOpen] = useState(false);
	const [height, setHeight] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	const handleFilterOpening = useCallback(() => {
		setIsOpen((prev) => !prev);
	}, []);

	useEffect(() => {
		if (!height || !isOpen || !ref.current) return undefined;
		const resizeObserver = new ResizeObserver((el) => {
			setHeight(el[0].contentRect.height);
		});
		resizeObserver.observe(ref.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, [height, isOpen]);

	useEffect(() => {
		if (isOpen) setHeight(ref.current?.getBoundingClientRect().height ?? 0);
		else setHeight(0);
	}, [isOpen]);

	return (
		<div className='collapsible-card-container'>
			<button
				type='button'
				onClick={handleFilterOpening}
				className='collapsible-header align-flex-center'
			>
				<Text
					variant='body_small_bold'
					as='div'
				>
					{header}
				</Text>
				<span className='collapsible-icon'>
					<span className={`collapsible-icon-button ${isOpen ? 'rotate-center down' : 'rotate-center up'}`} />
				</span>
			</button>
			<div
				className={`collapsible-content_wrapper ${isOpen ? 'content_opened' : 'content_closed'}`}
				style={{ height }}
			>
				<div ref={ref}>
					<div className='collapsible-content'>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default CollapsibleNavItem;
