import { capitalizeFirstLetter } from 'BreetHelpers';
import * as yup from 'yup';

export const getProviderOptions = (options?: Record<string, boolean>) =>
	options
		? Object.entries(options).map(([key]) => ({
				label: capitalizeFirstLetter(key),
				value: key,
			}))
		: [];

export const UpdateWithdrawalLimitSchema = yup.object({
	noKycNgn: yup.object({
		min: yup.number().required('Minimum No KYC (NGN) value is required'),
		max: yup.number().required('Maximum No KYC (NGN) value is required'),
	}),
	noKycGhs: yup.object({
		min: yup.number().required('Minimum No KYC (GHS) value is required'),
		max: yup.number().required('Maximum No KYC (GHS) value is required'),
	}),
	basicKycNgn: yup.object({
		max: yup.number().required('Maximum Basic KYC (NGN) value is required'),
	}),
	basicKycGhs: yup.object({
		max: yup.number().required('Maximum Basic KYC (GHS) value is required'),
	}),
});

export type UpdateWithdrawalLimitSchemaType = yup.InferType<typeof UpdateWithdrawalLimitSchema>;

const createFeeDiscountSchema = (category: string) =>
	yup
		.object({
			fee: yup.number().min(0, 'Fee must be 0 or greater').required('Fee is required'),
			discount: yup.number().min(0, 'Discount must be 0 or greater').required('Discount is required'),
		})
		.test((value, ctx) => {
			const fee = Number(value.fee);
			const discount = Number(value.discount);

			if (fee > 0 && discount > 0) {
				return ctx.createError({
					message: 'Either fee or discount can be greater than 0, but not both',
					path: discount > fee ? `${category}.fee` : `${category}.discount`,
					type: 'fee-or-discount',
				});
			}
			return true;
		});

export const UpdateBillsFeeDiscountSchema = yup.object({
	airtime: createFeeDiscountSchema('airtime'),
	betting: createFeeDiscountSchema('betting'),
	broadband: createFeeDiscountSchema('broadband'),
	data: createFeeDiscountSchema('data'),
	electricity: createFeeDiscountSchema('electricity'),
	tv: createFeeDiscountSchema('tv'),
});

export type UpdateBillsFeeDiscountSchemaType = yup.InferType<typeof UpdateBillsFeeDiscountSchema>;
export type UpdateBillsFeeDiscountSchemaKeyType = keyof UpdateBillsFeeDiscountSchemaType;

export const PriceAlertSchema = yup.object({
	alertLimit: yup.number().min(1, 'Alert limit must be 1 or greater').required('Alert limit is required'),
});

export type PriceAlertSchemaType = yup.InferType<typeof PriceAlertSchema>;

export const FxConversionAlertSchema = yup.object({
	NGNToGHS: yup.number().min(1, 'NGNToGHS must be 1 or greater').required('NGNToGHS is required'),
});

export type FxConversionAlertSchemaType = yup.InferType<typeof FxConversionAlertSchema>;

const createCryptoAssetSchema = (title: string, minValue = 1) =>
	yup.number().min(minValue, `${title} must be ${minValue} or greater`).required(`${title} is required`);

export const CryptoAssetRateSchema = yup.object({
	0: createCryptoAssetSchema('Value'),
	51: createCryptoAssetSchema('Value'),
	201: createCryptoAssetSchema('Value'),
	1001: createCryptoAssetSchema('Value'),
	5001: createCryptoAssetSchema('Value'),
	minimum: createCryptoAssetSchema('Minimum value', 0),
	confirmations: createCryptoAssetSchema('Confirmation value', 0),
});

export type CryptoAssetRateSchemaType = yup.InferType<typeof CryptoAssetRateSchema>;
export type CryptoAssetRateSchemaKeyType = keyof CryptoAssetRateSchemaType;

const createRewardsMetaSchema = (minValue = 0) =>
	yup.number().min(minValue, `Value must be ${minValue} or greater`).required('Value is required');

export const RewardValuesSchema = yup.object({
	rewardPerDollar: createRewardsMetaSchema(),
	referralReward: createRewardsMetaSchema(),
	signupPoints: createRewardsMetaSchema(),
	redeemLimit: createRewardsMetaSchema(),
});

export type RewardValuesSchemaType = yup.InferType<typeof RewardValuesSchema>;

export const RewardLevelSchema = yup.object({
	1: createRewardsMetaSchema(1),
	2: createRewardsMetaSchema(1),
	3: createRewardsMetaSchema(1),
	4: createRewardsMetaSchema(1),
	5: createRewardsMetaSchema(1),
});

export type RewardLevelSchemaType = yup.InferType<typeof RewardLevelSchema>;
export type RewardLevelSchemaKeyType = keyof RewardLevelSchemaType;
