import { Loader } from 'BreetComponents';
import { selectUser, useAppSelector } from 'BreetRedux';
import { Outlet } from 'react-router-dom';

import PageTopHeader from './components/PageTopHeader';
import SideNavBar from './components/SideNavBar';

export const ProtectedLayout = () => {
	const { data: currentUser } = useAppSelector(selectUser);

	return !currentUser?.data ? (
		<Loader />
	) : (
		<div className='bt_layout_admin'>
			<div className='bt_layout_admin_content'>
				<SideNavBar />
				<div className='bt_layout_admin_contain'>
					<PageTopHeader />
					<div className='bt_layout_admin_contain_view'>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};
