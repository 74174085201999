import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useUpdateUserFiatBalanceMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import type { AddOrRemoveFiatPointsSchemaType } from './userActions.helpers';
import { AddOrRemoveFiatPointsSchema } from './userActions.helpers';

const AddOrRemoveFiatBalance = () => {
	const modals = useAppSelector(selectModals);

	const [updateUserFiatBalance, { isLoading }] = useUpdateUserFiatBalanceMutation();

	const currentModal = modals.find((modal) => modal.id === 'addRemoveFiatBalance');
	const modalMetadata = currentModal?.metadata as Record<string, string | number | boolean> | undefined;
	const isAddingFiat = !!modalMetadata?.isAddingFiat;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(AddOrRemoveFiatPointsSchema),
		defaultValues: {
			currency: String(modalMetadata?.currency ?? ''),
		},
	});

	const onSubmit = useCallback(
		(payload: AddOrRemoveFiatPointsSchemaType) => {
			if (!modalMetadata) return;

			updateUserFiatBalance({ userId: modalMetadata.userId, isAddingFiat, ...payload })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('addRemoveFiatBalance');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[isAddingFiat, modalMetadata, updateUserFiatBalance]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Input
				label='Current Fiat Balance'
				placeholder='0.00'
				extValue={(modalMetadata?.balance as string | undefined) ?? ''}
				disabled
			/>
			<Input
				label={`${isAddingFiat ? 'Add to' : 'Remove'} Balance`}
				placeholder='0.00'
				register={register('amount')}
				errorMsg={errors.amount?.message}
			/>
			<TextareaInput
				label='Description'
				placeholder='Description'
				rows={3}
				register={register('reason')}
				errorMsg={errors.reason?.message}
			/>
			<Button
				size='large'
				variant={isAddingFiat ? 'main' : 'red'}
				loading={isLoading}
			>
				{isAddingFiat ? 'Add' : 'Remove'}
			</Button>
		</form>
	);
};

export default AddOrRemoveFiatBalance;
