import type {
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IWithdrawalDetailsQueryResp,
	IWithdrawalsQueryResp,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const withdrawalsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getWithdrawals: builder.query<IApiResponsePaginated<IWithdrawalsQueryResp[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/payments/withdrawals', params }),
			providesTags: ['GetAllWithdrawals'],
		}),
		getUserWithdrawals: builder.query<
			IApiResponsePaginated<IWithdrawalsQueryResp[]>,
			IApiPaginatedFilterQuery & { userId?: string }
		>({
			query: ({ userId, ...params }) => ({ url: `/admin/payments/withdrawals/user/${userId}`, params }),
			providesTags: ['GetUserWithdrawals'],
		}),
		getWithdrawalDetails: builder.query<IApiResponse<IWithdrawalDetailsQueryResp>, string | undefined>({
			query: (txReference) => ({ url: `/admin/payments/withdrawals/withdrawal/${txReference}` }),
			providesTags: ['GetSingleWithdrawal'],
		}),
		approveOrRejectWithdrawal: builder.mutation<
			IApiResponse<IWithdrawalDetailsQueryResp>,
			Record<string, string | boolean | string[] | undefined>
		>({
			query: (body) => ({ method: 'POST', url: '/admin/payments/withdrawals', body }),
			invalidatesTags: ['GetAllWithdrawals', 'GetUserWithdrawals', 'GetSingleWithdrawal', 'DashboardStats'],
		}),
		reverseWithdrawal: builder.mutation<IApiResponse, string[]>({
			query: (withdrawalId) => ({
				method: 'PATCH',
				url: '/admin/payments/withdrawals',
				body: { _ids: withdrawalId, reversed: true },
			}),
			invalidatesTags: ['GetAllWithdrawals', 'GetUserWithdrawals', 'GetSingleWithdrawal'],
		}),
	}),
});

export const {
	useGetWithdrawalsQuery,
	useGetUserWithdrawalsQuery,
	useGetWithdrawalDetailsQuery,
	useApproveOrRejectWithdrawalMutation,
	useReverseWithdrawalMutation,
} = withdrawalsApiSlice;
