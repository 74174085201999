import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalConfigIdType, ModalConfigStateType } from 'BreetConfig';

const initialModalState: ModalConfigStateType[] = [];

export const modalsSlice = createSlice({
	name: 'modals',
	initialState: initialModalState,
	reducers: (create) => ({
		openModal: create.reducer((state, action: PayloadAction<ModalConfigStateType>) => {
			const { payload } = action;
			const existingModal = state.find((item) => item.id === payload.id);
			if (existingModal) {
				Object.assign(existingModal, payload);
			} else {
				state.push(payload);
			}
		}),
		closeModal: create.reducer((state, action: PayloadAction<ModalConfigIdType>) => {
			const filteredState = state.filter((item) => item.id !== action.payload);
			return filteredState;
		}),
		closeModals: create.reducer(() => []),
	}),
	selectors: {
		selectModals: (state) => state,
	},
});

export const { closeModal, closeModals, openModal } = modalsSlice.actions;
export default modalsSlice.reducer;

export const { selectModals } = modalsSlice.selectors;
