import { matchStyle } from 'BreetHelpers';
import { useClickOutside } from 'BreetHooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ArrowIcon } from '@/assets/icons';

import { SkeletonLoader } from '../Loader';
import { Text } from '../Text';
import type { DropdownOptionType, DropdownProps } from './Dropdown.helpers';

const Dropdown = <E extends DropdownOptionType>({
	dropdownLabel,
	dropdownSize = 'regular',
	placeholder,
	className,
	selectedItem: externalSelectedItem,
	itemOptions,
	optionLabelKey = 'label',
	optionValueKey = 'value',
	loading,
	disabled,
	defaultSelectedItem,
	searchMode,
	errorMsg,
	onOptionClick,
	customOptionComponent,
}: DropdownProps<E>) => {
	const [isFocused, setIsFocused] = useState(false);
	const [internalSelectedItem, setInternalSelectedItem] = useState<E | null>(null);
	const [searchValue, setSearchValue] = useState('');

	const containerRef = useRef<HTMLDivElement>(null);

	const handleFocusToggle = useCallback(
		(val?: boolean) => () => {
			if (disabled) return;
			setIsFocused((prev) => val ?? !prev);
		},
		[disabled]
	);

	const filteredOptions = useMemo(
		() =>
			searchMode
				? itemOptions?.filter((option) => {
						const optionLabel = String(option[optionLabelKey]).toLowerCase();
						return optionLabel.includes(searchValue.toLowerCase());
					})
				: itemOptions,
		[itemOptions, optionLabelKey, searchMode, searchValue]
	);

	useClickOutside({
		targetElement: containerRef,
		onClickOutsideClick: () => {
			handleFocusToggle(false)();
		},
	});

	useEffect(() => {
		if (defaultSelectedItem) setInternalSelectedItem(defaultSelectedItem);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (externalSelectedItem !== undefined) setInternalSelectedItem(externalSelectedItem);
	}, [externalSelectedItem]);

	const isFocusedClassName = matchStyle(isFocused ? 'isFocused' : '', {
		isFocused: ' isFocused',
		default: '',
	});

	const isDisabledClassName = matchStyle(disabled ? 'disabled' : '', {
		disabled: ' disabled',
		default: '',
	});

	const wrapClassName = matchStyle(className, {
		[`${className}`]: ` ${className}`,
		default: '',
	});

	const handleOptionSelected = useCallback(
		(option: E) => (e: React.SyntheticEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			if (onOptionClick) onOptionClick(option);
			setInternalSelectedItem(option);
			handleFocusToggle(false)();
			if (searchValue) setSearchValue('');
		},
		[handleFocusToggle, onOptionClick, searchValue]
	);

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (searchMode) setSearchValue(e.target.value);
		},
		[searchMode]
	);

	return (
		<div className='bt_dropdown_wrapper'>
			{!!dropdownLabel && (
				<Text
					className='bt_dropdown_label'
					variant='body_small_bold'
				>
					{dropdownLabel}
				</Text>
			)}
			<div
				ref={containerRef}
				className={`bt_dropdown_contain ${dropdownSize}${isDisabledClassName}${isFocusedClassName}${wrapClassName}`}
			>
				<button
					type='button'
					onClick={handleFocusToggle()}
					className='bt_input_contain with_dropdown'
				>
					<input
						type='text'
						readOnly={!searchMode}
						value={(searchValue || (internalSelectedItem?.[optionLabelKey] as string | null)) ?? ''}
						onChange={handleInputChange}
						placeholder={placeholder}
					/>
					{loading ? (
						<span className='bt_input_suffixItem'>
							<span className='bt_button_isLoading' />
						</span>
					) : (
						<span className='bt_input_suffixItem'>
							<ArrowIcon type='arrowDown' />
						</span>
					)}
				</button>
				<div className='bt_dropdown_options'>
					<ul>
						{loading
							? Array.from({ length: 5 }, (_, key) => key + 1).map((item) => (
									<li
										key={item}
										className='bt_dropdown_options_item'
									>
										<SkeletonLoader height='4.933rem' />
									</li>
								))
							: filteredOptions?.map((option) => (
									<li key={option[optionValueKey] as string}>
										<button
											type='button'
											onClick={handleOptionSelected(option)}
											className={`bt_dropdown_options_item${internalSelectedItem === option ? ' selectedItem' : ''}`}
										>
											<span className='bt_dropdown_options_item_txt'>
												{customOptionComponent ? customOptionComponent(option) : option[optionLabelKey]}
											</span>
										</button>
									</li>
								))}
					</ul>
				</div>
			</div>

			{errorMsg ? (
				<Text
					className='bt_dropdown_error'
					variant='body_small_bold'
					as='span'
				>
					{errorMsg}
				</Text>
			) : null}
		</div>
	);
};

export default Dropdown;
