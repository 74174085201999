import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DateTimeSelector, Dropdown, type DropdownOptionType, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { type IFlashNoteMsg, spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useUpdateFlashMessageMutation } from 'BreetRedux';
import { formatISO } from 'date-fns';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import type { DateTimeValueReturnType } from '@/components/DateTimeSelector/DateTimeSelector.helpers';

import {
	flashMsgSeverityOptions,
	UpdateFlashMessageSchema,
	type UpdateFlashMessageSchemaType,
} from '../SiteMgtFlashNotes.helpers';

const UpdateFlashMessage = () => {
	const modals = useAppSelector(selectModals);

	const [updateFlashMessage, { isLoading }] = useUpdateFlashMessageMutation();

	const currentModal = modals.find((modal) => modal.id === 'updateFlashMessage');
	const modalMetadata = currentModal?.metadata as IFlashNoteMsg | undefined;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		watch,
	} = useForm({
		resolver: yupResolver(UpdateFlashMessageSchema),
		defaultValues: {
			message: modalMetadata?.message,
			severity: modalMetadata?.severity,
			expiresAt: modalMetadata?.expiresAt,
			isActive: true,
		},
	});

	const handleOptionClick = useCallback(
		(selectedItem?: DropdownOptionType) => {
			if (selectedItem) setValue('severity', selectedItem.value as string);
			if (errors.severity?.message) clearErrors('severity');
		},
		[clearErrors, errors.severity?.message, setValue]
	);

	const handleDateTimeChange = useCallback(
		(options: DateTimeValueReturnType) => {
			if (errors.expiresAt?.message) clearErrors('expiresAt');
			if (options.utcStartDate) setValue('expiresAt', formatISO(options.utcStartDate));
		},
		[clearErrors, errors.expiresAt?.message, setValue]
	);

	const onSubmit = useCallback(
		(payload: UpdateFlashMessageSchemaType) => {
			updateFlashMessage(payload as IFlashNoteMsg)
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('updateFlashMessage');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[updateFlashMessage]
	);

	const currentFormState = watch();

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Dropdown
				dropdownLabel='Select Severity'
				itemOptions={flashMsgSeverityOptions}
				defaultSelectedItem={flashMsgSeverityOptions.find(({ value }) => value === currentFormState.severity)}
				dropdownSize='large'
				placeholder='Select Severity'
				onOptionClick={handleOptionClick}
				errorMsg={errors.severity?.message}
			/>
			<TextareaInput
				label='Message'
				register={register('message')}
				errorMsg={errors.message?.message}
				rows={3}
			/>
			{!modalMetadata && (
				<DateTimeSelector
					label='Select Date/Time'
					onDateTimeChange={handleDateTimeChange}
					singleSelector
					errorMsg={errors.expiresAt?.message}
				/>
			)}
			<Button
				size='large'
				loading={isLoading}
			>
				Save
			</Button>
		</form>
	);
};

export default UpdateFlashMessage;
