import { matchStyle } from 'BreetHelpers';
import { useModal } from 'BreetHooks';
import React from 'react';
import { createPortal } from 'react-dom';

import { CloseIcon } from '@/assets/icons';

import { Button } from '../Button';
import { Text } from '../Text';
import type { ModalProps } from './Modal.helper';

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
	modalId,
	header = <>&nbsp;</>,
	manualClose,
	closeBtnRef,
	onClose,
	width,
	children,
	wrapperClassName,
}) => {
	const { modalState, mounted, modalElWrapperRef, modalContentRef, handleModalVisibility } = useModal({
		modalId,
		manualClose,
		onClose,
	});

	const modalStateClassName = matchStyle(modalState ? 'isOpen' : 'default', {
		isOpen: ' bt_modal_state--open',
		default: ' bt_modal_state--close',
	});

	const customClassName = matchStyle(wrapperClassName, {
		[`${wrapperClassName}`]: ` ${wrapperClassName}`,
		default: '',
	});

	return mounted && modalElWrapperRef.current ? (
		createPortal(
			<>
				<div className={`bt_modal_mask${modalStateClassName}${customClassName}`} />
				<div className={`bt_modal_container${modalStateClassName}${customClassName}`}>
					<div
						ref={modalContentRef}
						className='bt_modal_content'
						style={{ width }}
					>
						<div className='bt_modal_content_header'>
							{header ? <Text variant='display_mid_bold'>{header}</Text> : null}
							<Button
								ref={closeBtnRef}
								onClick={handleModalVisibility}
								id={`closeBtnRef-${modalId}`}
								className={`bt_modal_close_btn${modalStateClassName}`}
								title='close modal'
								iconEl={<CloseIcon />}
								btnType='icon'
								variant='white'
								size='regular'
							/>
						</div>
						{children}
					</div>
				</div>
			</>,
			modalElWrapperRef.current
		)
	) : (
		<>&nbsp;</>
	);
};

export default Modal;
