import { statusBgColorClassname, StatusColorOptionType } from 'BreetHelpers';
import { useTimer } from 'BreetHooks';

import { ToastIcon } from '@/assets/icons';

import { Text } from '../Text';
import type { IToast } from './Toast.helper';

const Toast = ({ duration, id, onClose, title, message, type, closeToast }: IToast) => {
	useTimer(duration ?? 4000, () => {
		if (onClose) onClose(id);
	});

	const defaultTitle: Record<StatusColorOptionType, string> = {
		success: 'Success',
		warning: 'Warning',
		error: 'Error',
		alternate: 'Info',
	};

	return (
		<div className={`bt_toastPill${closeToast ? ' closeToast' : ''}`}>
			<div className={`bt_toastPill_icon${statusBgColorClassname(type)}`}>
				<ToastIcon type={type} />
			</div>
			<div className='bt_toastPill_txt'>
				<Text variant='body_default_bold'>{title ?? defaultTitle[type]}</Text>
				<Text variant='body_small_regular'>{message}</Text>
			</div>
		</div>
	);
};

export default Toast;
