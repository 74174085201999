import * as yup from 'yup';

export const TagUserAccountSchema = yup.object({
	note: yup.string().required('Note is required'),
});

export type TagUserAccountSchemaType = yup.InferType<typeof TagUserAccountSchema>;

export const AddRemoveUserPointSchema = yup.object({
	points: yup.string().required('This is required'),
	reason: yup.string().required('Please provide a reason'),
});

export type AddRemoveUserPointSchemaType = yup.InferType<typeof AddRemoveUserPointSchema>;

export const EditUserProfileSchema = yup.object({
	name: yup.string(),
	email: yup.string(),
	phoneNumber: yup.string(),
});

export type EditUserProfileSchemaType = yup.InferType<typeof EditUserProfileSchema>;
export type EditUserProfileSchemaKeyType = keyof EditUserProfileSchemaType;

export const AddOrRemoveFiatPointsSchema = yup.object({
	amount: yup.string().required('Amount is required'),
	reason: yup.string().required('Please provide a reason'),
	currency: yup.string(),
});

export type AddOrRemoveFiatPointsSchemaType = yup.InferType<typeof AddOrRemoveFiatPointsSchema>;
