import type { AppState } from 'BreetRedux';

type PersistDataType<T> = Record<keyof T, T[keyof T]>;

export class StorageManager<T> {
	private readonly storageKey: string;

	constructor(storageKey: string) {
		this.storageKey = storageKey;
	}

	/**
	 * Reads the app configuration data from localStorage.
	 */
	private readData() {
		const storedData = localStorage.getItem(this.storageKey);
		return storedData ? (JSON.parse(storedData) as PersistDataType<T>) : null;
	}

	/**
	 * Updates the app configuration data associated with a specific key.
	 * @param {keyof T} key The key associated with the data to be updated.
	 * @param {T[keyof T]} newData The new data to be stored.
	 */
	private updateData<K extends keyof T>(key: K, newData: T[K]) {
		const appConfigData = this.readData();
		const updatedData = { ...appConfigData, [key]: newData };
		localStorage.setItem(this.storageKey, JSON.stringify(updatedData));
	}

	/**
	 * Removes the app configuration data associated with a specific key.
	 * @param {keyof T} key The key associated with the data to be removed.
	 */
	private removeData(key: keyof T) {
		const appConfigData = this.readData();
		const { [key]: removedKey, ...updatedData } = appConfigData;
		localStorage.setItem(this.storageKey, JSON.stringify(updatedData));
	}

	/**
	 * Retrieves the app configuration data associated with a specific key.
	 * @param {keyof T} key The key associated with the data to be retrieved.
	 */
	getData<K extends keyof T>(key: K) {
		const appConfigData = this.readData();
		return (appConfigData?.[key] as T[K]) ?? null;
	}

	/**
	 * Updates the app configuration data associated with a specific key.
	 * @param {keyof T} key The key associated with the data to be updated.
	 * @param {T[keyof T]} newData The new data to be stored.
	 */
	update<K extends keyof T>(key: K, newData: T[K]) {
		this.updateData(key, newData);
	}

	/**
	 * Removes the app configuration data associated with a specific key.
	 * @param {keyof T} key The key associated with the data to be removed.
	 */
	remove(key: keyof T) {
		this.removeData(key);
	}
}

export const reduxPersistor = new StorageManager<{
	app: Partial<AppState>;
}>('reduxPersistor');
