interface Props {
	type?: 'whiteColor';
}

export const BreetLogo = ({ type }: Props) => {
	if (type === 'whiteColor')
		return (
			<svg
				width='97'
				height='32'
				viewBox='0 0 97 32'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M66.3984 24.0938H68.1335C67.8473 24.1116 67.5611 24.1295 67.257 24.1295C66.9529 24.1295 66.6667 24.1295 66.3984 24.0938Z'
					fill='#006168'
				/>
				<path
					d='M80.7031 24.0938H82.4382C82.152 24.1116 81.8658 24.1295 81.5617 24.1295C81.2576 24.1295 80.9714 24.1295 80.7031 24.0938Z'
					fill='#006168'
				/>
				<path
					d='M96.1082 24.0938C95.3927 24.1295 94.7309 24.1295 94.1406 24.0938H96.1082Z'
					fill='#006168'
				/>
				<path
					d='M31.9821 14.1487C31.6959 2.59363 28.4941 0 16.0089 0C2.86193 0 0 2.86193 0 16.0089C0 29.1381 2.86193 32 15.9911 32H16.0089C17.4578 32 18.7993 31.9642 20.0156 31.8748C29.7462 31.2309 32 27.6713 32 15.9911C32.0179 15.3471 32 14.739 31.9821 14.1487Z'
					fill='#05A2BF'
				/>
				<path
					d='M25.1461 10.3564V12.5923C25.1103 12.968 25.003 13.3436 24.8062 13.6656C24.0908 14.8282 22.4451 15.5079 21.1752 15.7941C20.6564 15.9193 20.1198 15.973 19.5832 15.973H19.5653H11.7844H8.54688V13.7371H10.1209H19.5474H19.5653C20.1019 13.7371 20.6385 13.6656 21.1573 13.5582C21.998 13.3794 22.9817 13.0037 23.7688 12.4671C24.1802 12.1809 24.5379 11.8411 24.7883 11.4297C24.842 11.3581 24.8778 11.2866 24.9136 11.1971C25.0567 10.9467 25.1282 10.6605 25.1461 10.3564Z'
					fill='#022745'
				/>
				<path
					d='M25.524 17.5293V20.0872C25.4883 20.5164 25.3631 20.9457 25.1305 21.3214C24.3077 22.6629 22.4296 23.4499 20.9628 23.7719C20.3726 23.8971 19.7465 23.9687 19.1384 23.9865H19.1205H10.1948H6.49219V21.4287H8.29878H10.1948H19.1205H19.1384C19.7465 21.4287 20.3547 21.3571 20.9628 21.214C21.9109 20.9994 23.0556 20.588 23.9679 19.9441C24.4508 19.6042 24.8443 19.2107 25.1305 18.7635C25.1842 18.6741 25.22 18.5846 25.2736 18.5131C25.4167 18.1911 25.4883 17.8692 25.524 17.5293Z'
					fill='#022745'
				/>
				<path
					d='M25.524 17.5293C25.5062 17.8691 25.4167 18.1911 25.2736 18.5131C25.2379 18.6025 25.1842 18.6919 25.1305 18.7635C24.8443 19.2286 24.4329 19.6221 23.9679 19.944C23.0735 20.5701 21.9287 20.9994 20.9628 21.1961C20.3726 21.3213 19.7465 21.4108 19.1384 21.4108H19.1205H10.1948H8.29878H6.49219L11.9299 13.7372H8.56709L13.3608 6.95801H21.9645C22.9125 6.95801 23.8248 7.3873 24.4329 8.12067C24.9159 8.72883 25.2021 9.55163 25.1305 10.3387C25.1126 10.6249 25.0411 10.9289 24.9159 11.1972C24.8801 11.2688 24.8443 11.3403 24.7907 11.4298C24.5403 11.8233 24.1825 12.181 23.7711 12.4672C22.9841 13.0217 21.9824 13.3795 21.1596 13.5583C21.0165 13.5941 20.8734 13.612 20.7482 13.6478C21.4279 13.6478 21.8751 13.6478 21.8751 13.6478C22.9662 13.6478 24.0215 14.1307 24.7012 14.9893C25.2736 15.6869 25.5956 16.6349 25.524 17.5293Z'
					fill='white'
				/>
				<path
					d='M50.6053 16.5816C50.1044 15.7946 49.4247 15.2759 48.5483 15.0254C49.3174 14.8466 49.8898 14.3994 50.2654 13.7018C50.641 13.0042 50.8378 12.1814 50.8378 11.287C50.8378 9.98128 50.3191 8.94383 49.2637 8.1568C48.2263 7.38766 46.5986 6.99414 44.4342 6.99414H37.7266V24.1479H44.5415C46.9742 24.1479 48.7092 23.6828 49.7825 22.7527C50.8378 21.8225 51.3744 20.6241 51.3744 19.1574C51.3565 18.2272 51.1061 17.3686 50.6053 16.5816ZM40.66 9.42678H44.5237C45.8473 9.42678 46.7417 9.64143 47.2246 10.0528C47.6897 10.4642 47.9401 11.0903 47.9401 11.8952C47.9401 13.505 46.8669 14.31 44.7025 14.31H40.6779V9.42678H40.66ZM47.5824 21.0534C47.0815 21.5006 46.0977 21.7152 44.631 21.7152H40.66V16.5637H44.6846C45.2213 16.5637 45.6684 16.5816 46.0441 16.6353C46.4197 16.6889 46.7953 16.7963 47.1709 16.9751C47.5466 17.154 47.8328 17.4044 48.0295 17.7622C48.2263 18.1199 48.3157 18.5671 48.3157 19.1037C48.3336 19.9623 48.0832 20.6062 47.5824 21.0534Z'
					fill='white'
				/>
				<path
					d='M59.619 10.4105C58.4385 10.4105 57.4905 10.6072 56.775 11.0186C56.0595 11.43 55.5766 11.8951 55.3082 12.4317L54.6643 10.5893H53.1797V24.0941H55.9701V16.5278C55.9701 15.3115 56.2026 14.3814 56.6677 13.7553C57.1327 13.1293 58.0092 12.8252 59.315 12.8252C59.3865 12.8252 59.5117 12.8252 59.6727 12.8431C59.8516 12.861 59.9768 12.861 60.0662 12.861V10.3926H59.619V10.4105Z'
					fill='white'
				/>
				<path
					d='M70.3155 19.8548C69.9756 21.2858 68.9024 21.9834 67.1316 21.9834C64.8242 21.9834 63.5899 20.7671 63.429 18.3523H73.1774C73.249 17.4759 73.249 16.6709 73.1595 15.9197C73.088 15.1684 72.9091 14.4351 72.6408 13.7553C72.3725 13.0756 72.0148 12.4854 71.5497 11.9845C71.0846 11.5016 70.4944 11.1081 69.7431 10.8219C69.0097 10.5357 68.1511 10.3926 67.2031 10.3926C62.7492 10.3926 60.5312 12.6642 60.5312 17.2254C60.5312 21.5183 62.4809 23.8079 66.3982 24.0762C66.6665 24.0941 66.9527 24.112 67.2389 24.112C67.543 24.112 67.8471 24.0941 68.1154 24.0762C69.6358 23.9689 70.7984 23.5932 71.6034 22.9135C72.5514 22.1265 73.088 21.0891 73.2311 19.8191H70.3155V19.8548ZM66.9527 12.5748C68.1333 12.5748 68.9918 12.9147 69.5285 13.5765C70.0651 14.2562 70.3334 15.079 70.3155 16.0986C70.3334 16.0986 70.3334 16.0986 70.3155 16.1164C70.3334 16.1343 70.3334 16.1522 70.3155 16.1522H63.4647C63.6078 13.7732 64.7705 12.5748 66.9527 12.5748Z'
					fill='white'
				/>
				<path
					d='M84.628 19.8548C84.2881 21.2858 83.2149 21.9834 81.4441 21.9834C79.1367 21.9834 77.9024 20.7671 77.7415 18.3523H87.4899C87.5615 17.4759 87.5615 16.6709 87.472 15.9197C87.4005 15.1684 87.2216 14.4351 86.9533 13.7553C86.685 13.0756 86.3273 12.4854 85.8622 11.9845C85.3971 11.5016 84.8069 11.1081 84.0556 10.8219C83.3222 10.5357 82.4636 10.3926 81.5156 10.3926C77.0617 10.3926 74.8438 12.6642 74.8438 17.2254C74.8438 21.5183 76.7934 23.8079 80.7107 24.0762C80.979 24.0941 81.2652 24.112 81.5514 24.112C81.8555 24.112 82.1596 24.0941 82.4279 24.0762C83.9483 23.9689 85.1109 23.5932 85.9159 22.9135C86.8639 22.1265 87.4005 21.0891 87.5436 19.8191H84.628V19.8548ZM81.2652 12.5748C82.4458 12.5748 83.3043 12.9147 83.841 13.5765C84.3776 14.2562 84.6459 15.079 84.628 16.0986C84.6459 16.0986 84.6459 16.0986 84.628 16.1164C84.6459 16.1343 84.6459 16.1522 84.628 16.1522H77.7772C77.9382 13.7732 79.1009 12.5748 81.2652 12.5748Z'
					fill='white'
				/>
				<path
					d='M96.2737 12.879V10.6431H92.7857V6.99414H90.0132V10.6431L88.1172 11.2155V12.879H90.0132V20.159C90.0132 21.7331 90.4962 22.8063 91.48 23.4145C92.106 23.808 93.0004 24.0405 94.163 24.1121C94.7533 24.1479 95.3972 24.1479 96.1306 24.1121C96.1843 24.1121 96.2379 24.1121 96.2916 24.1121V21.8762C94.9143 21.9477 94.002 21.8583 93.5549 21.59C93.1077 21.3217 92.8751 20.8566 92.8751 20.1769V12.8969H96.2737V12.879Z'
					fill='white'
				/>
			</svg>
		);

	return (
		<svg
			width='142'
			height='28'
			viewBox='0 0 142 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M94.5625 27.0508H97.3015C96.8497 27.0788 96.3979 27.1069 95.9179 27.1069C95.4379 27.1069 94.9861 27.1069 94.5625 27.0508Z'
				fill='#006168'
			/>
			<path
				d='M117.156 27.0508H119.895C119.443 27.0788 118.992 27.1069 118.512 27.1069C118.032 27.1069 117.58 27.1069 117.156 27.0508Z'
				fill='#006168'
			/>
			<path
				d='M141.465 27.0508C140.336 27.1069 139.291 27.1069 138.359 27.0508H141.465Z'
				fill='#006168'
			/>
			<path
				d='M29.454 5.36523V8.89486C29.3975 9.48784 29.2281 10.0808 28.9175 10.5891C27.788 12.4245 25.1902 13.4975 23.1853 13.9493C22.3665 14.147 21.5194 14.2317 20.6722 14.2317H20.644H8.3609H3.25V10.702H5.73486H20.6158H20.644C21.4911 10.702 22.3382 10.5891 23.1571 10.4197C24.4843 10.1373 26.0373 9.54431 27.2797 8.6972C27.9292 8.24541 28.4939 7.70891 28.8892 7.05946C28.9739 6.94651 29.0304 6.83356 29.0869 6.69238C29.3128 6.29706 29.4257 5.84526 29.454 5.36523Z'
				fill='#022745'
			/>
			<path
				d='M30.0442 16.6885V20.7264C29.9877 21.4041 29.7901 22.0818 29.423 22.6747C28.1241 24.7925 25.1592 26.0349 22.8438 26.5432C21.9119 26.7409 20.9236 26.8538 19.9636 26.8821H19.9353H5.84506H0V22.8442H2.85193H5.84506H19.9353H19.9636C20.9236 22.8442 21.8837 22.7312 22.8438 22.5053C24.3403 22.1665 26.1475 21.517 27.5876 20.5005C28.35 19.964 28.9712 19.3428 29.423 18.6368C29.5077 18.4957 29.5642 18.3545 29.6489 18.2415C29.8748 17.7332 29.9877 17.225 30.0442 16.6885Z'
				fill='#022745'
			/>
			<path
				d='M30.0442 16.6881C30.016 17.2246 29.8748 17.7329 29.6489 18.2411C29.5924 18.3823 29.5077 18.5235 29.423 18.6364C28.9712 19.3706 28.3217 19.9918 27.5876 20.5001C26.1757 21.4884 24.3686 22.1661 22.8438 22.4767C21.9119 22.6743 20.9236 22.8155 19.9636 22.8155H19.9353H5.84506H2.85193H0L8.58406 10.7018H3.27549L10.843 0H24.425C25.9216 0 27.3617 0.677689 28.3217 1.83541C29.0842 2.79547 29.5359 4.09437 29.423 5.3368C29.3948 5.78859 29.2818 6.26863 29.0842 6.69218C29.0277 6.80513 28.9712 6.91808 28.8865 7.05926C28.4912 7.68048 27.9264 8.24521 27.277 8.69701C26.0345 9.57235 24.4533 10.1371 23.1544 10.4195C22.9285 10.476 22.7026 10.5042 22.5049 10.5607C23.5779 10.5607 24.2839 10.5607 24.2839 10.5607C26.0063 10.5607 27.6723 11.3231 28.7453 12.6784C29.6489 13.7797 30.1572 15.2762 30.0442 16.6881Z'
				fill='#00E2ED'
			/>
			<path
				d='M69.6354 15.1917C68.8447 13.9493 67.7717 13.1304 66.3881 12.7351C67.6023 12.4527 68.5059 11.7468 69.0989 10.6455C69.6918 9.54429 70.0024 8.24538 70.0024 6.83353C70.0024 4.77223 69.1836 3.13448 67.5176 1.89205C65.8798 0.677855 63.3103 0.0566406 59.8936 0.0566406H49.3047V27.136H60.063C63.9032 27.136 66.6422 26.4018 68.3365 24.9335C70.0024 23.4651 70.8495 21.5733 70.8495 19.2578C70.8213 17.7895 70.426 16.4341 69.6354 15.1917ZM53.9356 3.89687H60.0348C62.1243 3.89687 63.5362 4.23572 64.2986 4.88517C65.0327 5.53462 65.428 6.52291 65.428 7.79358C65.428 10.3349 63.7338 11.6056 60.3171 11.6056H53.9638V3.89687H53.9356ZM64.8633 22.2509C64.0727 22.9569 62.5196 23.2957 60.2042 23.2957H53.9356V15.1635H60.2889C61.136 15.1635 61.8419 15.1917 62.4349 15.2764C63.0279 15.3611 63.6209 15.5305 64.2138 15.8129C64.8068 16.0953 65.2586 16.4906 65.5692 17.0553C65.8798 17.6201 66.021 18.326 66.021 19.1731C66.0493 20.5285 65.6539 21.545 64.8633 22.2509Z'
				fill='currentColor'
			/>
			<path
				d='M83.8685 5.45011C82.0048 5.45011 80.5082 5.76072 79.3788 6.41017C78.2493 7.05962 77.4869 7.79378 77.0633 8.64089L76.0468 5.73248H73.7031V27.0514H78.1081V15.1072C78.1081 13.1871 78.4752 11.7187 79.2093 10.7304C79.9435 9.74214 81.3271 9.26211 83.3884 9.26211C83.5014 9.26211 83.699 9.26212 83.9532 9.29035C84.2355 9.31859 84.4332 9.31858 84.5744 9.31858V5.42188H83.8685V5.45011Z'
				fill='currentColor'
			/>
			<path
				d='M100.75 20.3593C100.214 22.6182 98.5196 23.7195 95.7242 23.7195C92.0816 23.7195 90.1332 21.7994 89.8791 17.9874H105.268C105.381 16.6037 105.381 15.3331 105.24 14.1471C105.127 12.9612 104.845 11.8034 104.421 10.7304C103.998 9.65743 103.433 8.7256 102.699 7.93496C101.965 7.17256 101.033 6.55136 99.8468 6.09956C98.689 5.64777 97.3337 5.42188 95.8371 5.42188C88.8061 5.42188 85.3047 9.00798 85.3047 16.2084C85.3047 22.9853 88.3825 26.5997 94.5664 27.0232C94.99 27.0514 95.4418 27.0797 95.8936 27.0797C96.3736 27.0797 96.8536 27.0514 97.2772 27.0232C99.6773 26.8538 101.513 26.2608 102.783 25.1878C104.28 23.9454 105.127 22.3076 105.353 20.3028H100.75V20.3593ZM95.4418 8.8668C97.3054 8.8668 98.6608 9.40329 99.5079 10.4481C100.355 11.5211 100.779 12.82 100.75 14.4295C100.779 14.4295 100.779 14.4295 100.75 14.4577C100.779 14.486 100.779 14.5142 100.75 14.5142H89.9356C90.1615 10.7587 91.9969 8.8668 95.4418 8.8668Z'
				fill='currentColor'
			/>
			<path
				d='M123.344 20.3593C122.808 22.6182 121.113 23.7195 118.318 23.7195C114.675 23.7195 112.727 21.7994 112.473 17.9874H127.862C127.975 16.6037 127.975 15.3331 127.834 14.1471C127.721 12.9612 127.438 11.8034 127.015 10.7304C126.591 9.65743 126.027 8.7256 125.292 7.93496C124.558 7.17256 123.626 6.55136 122.441 6.09956C121.283 5.64777 119.927 5.42188 118.431 5.42188C111.4 5.42188 107.898 9.00798 107.898 16.2084C107.898 22.9853 110.976 26.5997 117.16 27.0232C117.584 27.0514 118.036 27.0797 118.487 27.0797C118.967 27.0797 119.447 27.0514 119.871 27.0232C122.271 26.8538 124.107 26.2608 125.377 25.1878C126.874 23.9454 127.721 22.3076 127.947 20.3028H123.344V20.3593ZM118.036 8.8668C119.899 8.8668 121.255 9.40329 122.102 10.4481C122.949 11.5211 123.372 12.82 123.344 14.4295C123.372 14.4295 123.372 14.4295 123.344 14.4577C123.372 14.486 123.372 14.5142 123.344 14.5142H112.529C112.783 10.7587 114.619 8.8668 118.036 8.8668Z'
				fill='currentColor'
			/>
			<path
				d='M141.728 9.34662V5.817H136.221V0.0566406H131.845V5.817L128.852 6.72058V9.34662H131.845V20.8391C131.845 23.324 132.607 25.0182 134.16 25.9782C135.148 26.5995 136.56 26.9665 138.396 27.0795C139.328 27.136 140.344 27.136 141.502 27.0795C141.586 27.0795 141.671 27.0795 141.756 27.0795V23.5499C139.582 23.6628 138.142 23.5216 137.436 23.0981C136.73 22.6745 136.363 21.9404 136.363 20.8673V9.37486H141.728V9.34662Z'
				fill='currentColor'
			/>
		</svg>
	);
};
