import { closeModal, selectModals, useAppDispatch, useAppSelector } from 'BreetRedux';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { ModalProps } from '@/components/Modal/Modal.helper';

export const useModal = ({ modalId, manualClose, onClose }: ModalProps) => {
	const [modalState, setModalState] = useState<boolean>();
	const [mounted, setMounted] = useState(false);

	const modalElWrapperRef = useRef<HTMLDivElement | null>(null);
	const modalContentRef = useRef<HTMLDivElement | null>(null);
	const dispatch = useAppDispatch();
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modalId !== 'initialModal' && modal.id === modalId);

	useEffect(() => {
		const rootElement = document.getElementById('bt_modal_root');
		if (currentModal) {
			setModalState(true);
			if (!modalElWrapperRef.current) {
				modalElWrapperRef.current = document.createElement('div');
			}
			setMounted(true);
			const el = modalElWrapperRef.current;
			rootElement?.appendChild(el);
			return () => {
				rootElement?.removeChild(el);
			};
		}
		return undefined;
	}, [currentModal]);

	const handleModalVisibility = useCallback(() => {
		if (!manualClose) setModalState(false);
		setTimeout(() => {
			if (onClose) onClose(false);
			dispatch(closeModal(modalId));
		}, 300);
	}, [dispatch, manualClose, modalId, onClose]);

	return {
		modalState,
		mounted,
		modalElWrapperRef,
		modalContentRef,
		handleModalVisibility,
	};
};
