export const CloseIcon = () => (
	<svg
		width='14'
		height='13'
		viewBox='0 0 14 13'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M6.99681 5.08623L11.9468 0.13623L13.3608 1.55023L8.41081 6.50023L13.3608 11.4502L11.9468 12.8642L6.99681 7.91423L2.04681 12.8642L0.632812 11.4502L5.58281 6.50023L0.632812 1.55023L2.04681 0.13623L6.99681 5.08623Z'
			fill='currentColor'
		/>
	</svg>
);
