import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DataInsight, Input } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { deepMerge, formHasChanges, type ISettingsSupportLine } from 'BreetHelpers';
import { useUpdateSettingsHandler } from 'BreetHooks';
import { selectSupportLine, useAppSelector } from 'BreetRedux';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { EditSupportLineSchema, type EditSupportLineSchemaKeyType, getSupportLineTitle } from './SiteMgtOther.helpers';

const EditSupportLine = () => {
	const supportLine = useAppSelector(selectSupportLine);

	const { isLoading, handleUpdateSettings } = useUpdateSettingsHandler();

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(EditSupportLineSchema),
	});

	const handleFormValueInitUpdate = useCallback(() => {
		if (!supportLine) return;

		Object.entries(supportLine).forEach(([line, { isEnabled, value }]) => {
			setValue(`${line as EditSupportLineSchemaKeyType}.isEnabled`, isEnabled);
			setValue(`${line as EditSupportLineSchemaKeyType}.value`, value);
		});
	}, [setValue, supportLine]);

	useEffect(() => {
		handleFormValueInitUpdate();
	}, [handleFormValueInitUpdate]);

	const handleModalClose = useCallback(() => {
		closeModalById('editReferral');
	}, []);

	const onSubmit = useCallback(
		(payload: ISettingsSupportLine) => {
			if (
				formHasChanges({
					defaultValues: supportLine,
					currentValues: deepMerge(supportLine, payload),
				})
			)
				handleUpdateSettings({
					key: 'supportLine',
					value: deepMerge(supportLine, payload),
					onSuccess: handleModalClose,
				});
			else handleModalClose();
		},
		[handleModalClose, handleUpdateSettings, supportLine]
	);

	return (
		<div className='supportLine_edit'>
			<div className='supportLine_edit_form'>
				{!!supportLine &&
					Object.keys(supportLine).map((line) => (
						<DataInsight
							key={line}
							title={getSupportLineTitle(line)}
							customValue={
								<Input
									size='small'
									register={register(`${line as EditSupportLineSchemaKeyType}.value`)}
									errorMsg={errors[line as EditSupportLineSchemaKeyType]?.value?.message}
								/>
							}
						/>
					))}
			</div>

			<div className='supportLine_edit_cta'>
				<Button
					variant='gray'
					onClick={handleModalClose}
				>
					Cancel
				</Button>
				<Button
					variant='blue'
					onClick={handleSubmit(onSubmit)}
					loading={isLoading}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default EditSupportLine;
