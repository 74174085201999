import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dropdown, type DropdownOptionType, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { type IFlashNoteMsg, spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useUpdateFeaturedNoteMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { featuredNoteFeatureOptions, UpdateFeaturedNoteSchema, UpdateFeaturedNoteSchemaType } from '../SiteMgtFlashNotes.helpers';

const UpdateFeaturedNotes = () => {
	const modals = useAppSelector(selectModals);

	const [updateFeaturedNote, { isLoading }] = useUpdateFeaturedNoteMutation();

	const currentModal = modals.find((modal) => modal.id === 'updateFeaturedNote');
	const modalMetadata = currentModal?.metadata as IFlashNoteMsg | undefined;

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		watch,
	} = useForm({
		resolver: yupResolver(UpdateFeaturedNoteSchema),
		defaultValues: {
			message: modalMetadata?.message,
			feature: modalMetadata?.feature,
			isActive: true,
		},
	});

	const handleOptionClick = useCallback(
		(selectedItem?: DropdownOptionType) => {
			if (selectedItem) setValue('feature', selectedItem.value as string);
			if (errors.feature?.message) clearErrors('feature');
		},
		[clearErrors, errors.feature?.message, setValue]
	);

	const onSubmit = useCallback(
		(payload: UpdateFeaturedNoteSchemaType) => {
			updateFeaturedNote(payload as IFlashNoteMsg)
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('updateFeaturedNote');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[updateFeaturedNote]
	);

	const currentFormState = watch();

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Dropdown
				dropdownLabel='Select Feature'
				itemOptions={featuredNoteFeatureOptions}
				defaultSelectedItem={featuredNoteFeatureOptions.find(({ value }) => value === currentFormState.feature)}
				dropdownSize='large'
				placeholder='Select Severity'
				onOptionClick={handleOptionClick}
				errorMsg={errors.feature?.message}
			/>
			<TextareaInput
				label='Message'
				register={register('message')}
				errorMsg={errors.message?.message}
				rows={3}
			/>
			<Button
				size='large'
				loading={isLoading}
			>
				Save
			</Button>
		</form>
	);
};

export default UpdateFeaturedNotes;
