import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { type IUserReferral, spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useUpdateReferralMutation } from 'BreetRedux';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { EditReferralFomSchema, type EditReferralFomSchemaType } from './SiteMgtReferral.helper';

const EditReferralForm = () => {
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modal.id === 'editReferral');
	const modalMetadata = currentModal?.metadata as IUserReferral;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(EditReferralFomSchema),
		defaultValues: {
			referralCode: modalMetadata.referralCode,
			points: modalMetadata.points || 0,
		},
	});

	const [updateReferral, { isLoading }] = useUpdateReferralMutation();

	const onSubmit = useCallback(
		(payload: EditReferralFomSchemaType) => {
			updateReferral({ ...payload, referralId: modalMetadata._id })
				.unwrap()
				.then((resp) => {
					spawnAppToast({ dataMsg: resp, type: 'success' });
					closeModalById('editReferral');
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		},
		[modalMetadata._id, updateReferral]
	);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className='modalFormWrapper'
		>
			<Input
				label='Referral Code'
				register={register('referralCode')}
				errorMsg={errors.referralCode?.message}
			/>

			<Input
				type='number'
				label='Points'
				register={register('points')}
				errorMsg={errors.points?.message}
			/>

			<Button
				size='large'
				loading={isLoading}
			>
				Save
			</Button>
		</form>
	);
};

export default EditReferralForm;
