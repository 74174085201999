import { matchStyle } from 'BreetHelpers';
import React from 'react';

import type { IText } from './Text.helper';

const Text: React.FC<IText> = ({ as = 'p', className, variant = 'body_default_regular', children, ...rest }) => {
	const defaultVariantClassNames: Record<typeof variant, string> = {
		heading_huge: 'txt-50 fw-600',
		display_large: 'txt-45 fw-600',
		body_head_large: 'txt-32 fw-600',
		display_mid_bold: 'txt-20 fw-600',
		display_mid_regular: 'txt-24',
		body_big: 'txt-20',
		body_default_bold: 'txt-16 fw-600',
		body_default_regular: 'txt-16',
		body_small_bold: 'txt-14 fw-600',
		body_small_regular: 'txt-14',
		body_tiny: 'txt-10',
	};

	const extendedClassName = matchStyle(className, {
		[`${className}`]: ` ${className}`,
		default: '',
	});

	const Comp = as;

	return (
		<Comp
			className={`${defaultVariantClassNames[variant]}${extendedClassName}`}
			{...rest}
		>
			{children}
		</Comp>
	);
};

export default Text;
