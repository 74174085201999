import 'react-datepicker/dist/react-datepicker.css';

import { dateConst } from 'BreetHelpers';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { ArrowIcon, FilterIcon } from '@/assets/icons';

import { Button } from '../Button';
import { Text } from '../Text';
import type { DateTimeInitType, IDateTimeSelector } from './DateTimeSelector.helpers';

const DateTimeSelector = ({ singleSelector, label, errorMsg, onDateTimeChange, onDateTimeFilterClick }: IDateTimeSelector) => {
	const [startDate, setStartDate] = useState<DateTimeInitType>(null);
	const [endDate, setEndDate] = useState<DateTimeInitType>(null);

	useEffect(() => {
		if ((startDate ?? endDate) && onDateTimeChange)
			onDateTimeChange({
				startDate,
				endDate,
				utcStartDate: formatInTimeZone(startDate ?? new Date(), 'UTC', dateConst.FULL_DATE_TIME_FORMAT),
				utcEndDate: formatInTimeZone(endDate ?? new Date(), 'UTC', dateConst.FULL_DATE_TIME_FORMAT),
			});
	}, [endDate, onDateTimeChange, startDate]);

	const handleStartDateChange = useCallback((date: DateTimeInitType) => {
		setStartDate(date);
	}, []);

	const handleEndDateChange = useCallback((date: DateTimeInitType) => {
		setEndDate(date);
	}, []);

	const handleApplyFilter = useCallback(() => {
		if (!startDate || !endDate) return;

		if (onDateTimeFilterClick)
			onDateTimeFilterClick({
				startDate,
				endDate,
				utcStartDate: formatInTimeZone(startDate, 'UTC', dateConst.FULL_DATE_TIME_FORMAT),
				utcEndDate: formatInTimeZone(endDate, 'UTC', dateConst.FULL_DATE_TIME_FORMAT),
			});
	}, [endDate, onDateTimeFilterClick, startDate]);

	const singleDateSelectorClassname = singleSelector ? ' singleSelector' : '';

	return (
		<div className='bt_dateTimeSelector_wrapper'>
			{!!label && (
				<Text
					variant='body_small_bold'
					className='bt_dateTimeSelector_label'
				>
					{label}
				</Text>
			)}

			<div className='bt_dateTimeSelector'>
				<div className={`bt_dateTimeSelector_dateTimeWrapper${singleDateSelectorClassname}`}>
					<div className='bt_dateTimeSelector_dateTimeWrapper_lt_placeholder'>
						{singleSelector ? 'Select Date' : 'Start Date'}
					</div>
					<div className={`bt_dateTimeSelector_dateTimeWrapper_lt${startDate ? ' valid' : ''}`}>
						<DatePicker
							selected={startDate ?? new Date()}
							onChange={handleStartDateChange}
							selectsStart
							startDate={startDate ?? new Date()}
							endDate={endDate ?? new Date()}
							showTimeSelect
							dateFormat={dateConst.CALENDAR_DATE_TIME_FORMAT}
							timeIntervals={1}
						/>
					</div>
					{!singleSelector && (
						<>
							<ArrowIcon />

							<div className='bt_dateTimeSelector_dateTimeWrapper_rt'>
								<DatePicker
									selected={endDate ?? new Date()}
									onChange={handleEndDateChange}
									selectsEnd
									startDate={startDate ?? new Date()}
									endDate={endDate ?? new Date()}
									minDate={startDate ?? new Date()}
									showTimeSelect
									dateFormat={dateConst.CALENDAR_DATE_TIME_FORMAT}
									timeIntervals={1}
								/>
							</div>
							<div className='bt_dateTimeSelector_dateTimeWrapper_rt_placeholder'>End Date</div>
						</>
					)}
				</div>
				{!singleSelector && (
					<Button
						disabled={!startDate || !endDate}
						onClick={handleApplyFilter}
					>
						Apply&nbsp;&nbsp;
						<FilterIcon />
					</Button>
				)}
			</div>

			{errorMsg ? (
				<Text
					className='bt_dateTimeSelector_error'
					variant='body_small_bold'
					as='span'
				>
					{errorMsg}
				</Text>
			) : null}
		</div>
	);
};

export default DateTimeSelector;
