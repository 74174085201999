export const BillsIcon = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_7161_3858)'>
			<path
				d='M10.8998 2.09961L20.7988 3.51461L22.2128 13.4146L13.0208 22.6066C12.8333 22.7941 12.579 22.8994 12.3138 22.8994C12.0487 22.8994 11.7944 22.7941 11.6068 22.6066L1.70685 12.7066C1.51938 12.5191 1.41406 12.2648 1.41406 11.9996C1.41406 11.7344 1.51938 11.4801 1.70685 11.2926L10.8998 2.09961ZM11.6068 4.22161L3.82785 11.9996L12.3138 20.4846L20.0918 12.7066L19.0318 5.28161L11.6068 4.22161ZM13.7268 10.5856C13.3517 10.2103 13.141 9.70139 13.1411 9.17076C13.1411 8.90801 13.1929 8.64785 13.2935 8.40513C13.3941 8.1624 13.5415 7.94186 13.7273 7.75611C13.9132 7.57035 14.1338 7.42302 14.3765 7.32251C14.6193 7.22201 14.8795 7.1703 15.1422 7.17035C15.6728 7.17044 16.1817 7.38133 16.5568 7.75661C16.932 8.13189 17.1427 8.64083 17.1426 9.17146C17.1425 9.7021 16.9316 10.211 16.5563 10.5861C16.1811 10.9613 15.6721 11.172 15.1415 11.1719C14.6109 11.1718 14.102 10.9609 13.7268 10.5856Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_7161_3858'>
				<rect
					width='24'
					height='24'
					fill='currentColor'
				/>
			</clipPath>
		</defs>
	</svg>
);
